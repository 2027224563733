import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CrosBawIcon from '../../assets/image/crosbaw.svg';
import logo from "../../assets/image/logo.png";
import WeeklyChallengeIcon from '../../assets/image/weekly-challenge-icon.png';
import { logout, setLevelDataState, UPDATE_BALANCE } from '../../redux/slice/authSlice';
import WeeklyPopupBgImg from '../../assets/image/weeklyPopupBgImg.png';
import { AboutIcon, BlogIcon, CartIcon2, ContactIcon, DepositIcon, EmailIcon, HomeIcon, WalletIcon } from '../../utils/Icons';
import axiosInstance from '../../utils/AxiosInstance';
import { resetGamesState } from '../../redux/slice/gamesSlice';


const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [walletDropdownVisible, setWalletDropdownVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [weeklyChallengeData, setWeeklyChallengeData] = useState([]);
    const [walletData, setWalletData] = useState(null);
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [notificationCount, setNotificationCount] = useState(0);
    const token = useSelector(state => state.auth.token);
    const userData = useSelector(state => state.auth.user);
    const cartData = useSelector((state) => state.games.cart);
    const { activeLevel, main_balance, bonus_balance, total_deposit } = useSelector(state => state.auth);

    const FindGetActiveLevel = async () => {
        // Fetch bounus level data
        try {
            const response = await axiosInstance.post('/bounus_level.php');
            if (response.data && response.data.status.code === 1) {
                const enrichedData = response.data.data; // Assume enrichData is a function to process data
                dispatch(setLevelDataState(enrichedData));
            } else {
                console.error('Failed to fetch bonus levels: Invalid response');
            }
        } catch (error) {
            console.error('Failed to fetch bonus levels:', error);
        }
    }

    useEffect(() => {
        if (!activeLevel) {
            FindGetActiveLevel();
        }
    }, [activeLevel])

    // Find level 
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (total_deposit && activeLevel) {
            const validMainBalance = !total_deposit || total_deposit === 0
                ? 1
                : total_deposit;
            const progressCount = (validMainBalance / activeLevel.max_deposit_limit) * 100;
            setProgress(progressCount);
        }
    }, [total_deposit && activeLevel]);

    const [today, setToday] = useState('');

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // Refs to detect clicks outside
    const dropdownRef = useRef(null);
    const walletDropdownRef = useRef(null);

    // Function to determine the background color for specific elements
    const getBackgroundColor = (path) => {
        return location.pathname === path ? '#D9D9D91A' : 'transparent';
    };


    const handleLogout = () => {
        dispatch(logout());
        dispatch(resetGamesState());
        navigate("/login");
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
        setWalletDropdownVisible(false);
    };

    const toggleWalletDropdown = () => {
        setWalletDropdownVisible(!walletDropdownVisible);
        setDropdownVisible(false);
    }

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current && !dropdownRef.current.contains(event.target)
            ) {
                setDropdownVisible(false);
            }
            if (
                walletDropdownRef.current && !walletDropdownRef.current.contains(event.target)
            ) {
                setWalletDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        getGeneralWalletData();
    }, [dropdownVisible, walletDropdownVisible]);

    useEffect(() => {
        getNotificationCount();
    }, [])

    const getGeneralWalletData = async () => {
        try {
            const response = await axiosInstance.get('/user_balance.php');
            const { main_balance, bonus_balance, total_deposit } = response.data.data;

            // Dispatch the action to update the balance
            dispatch(UPDATE_BALANCE({ main_balance, bonus_balance, total_deposit }));
            setWalletData(response?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getNotificationCount = async () => {
        try {
            const response = await axiosInstance.get('/notification_count.php');
            setNotificationCount(response?.data?.data?.total_count);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/weekly_challenge_bonus_percentage.php');
                if (response?.data?.status?.code === 1) {
                    setWeeklyChallengeData(response?.data?.data || []);
                } else {
                    console.error('Error fetching weekly challenge data:', response?.data?.status?.message);
                    // Optionally, set an error state or display a message
                }
            } catch (error) {
                console.error('Error fetching weekly challenge data:', error);
                // Optionally, set an error state or display a message
            }
        };

        fetchData();

        const todayDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });
        setToday(todayDay);
    }, []);

    return (
        <>
            <nav className="w-full bg-[#ffffff0f] absolute top-0 z-50">
                {/* First Line */}
                <div className="flex items-center justify-between h-[60px] px-4 xl:px-8 text-white">
                    <div className="flex gap-3">
                        <Link to="/home" className="flex items-center gap-1">
                            <img src={logo} className="h-[38.5px] w-10 object-contain" alt="logo" />
                            <div className="flex flex-col gap-1">
                                <h1 className="leading-none text-white">LUCKY CHARM</h1>
                                <h2 className="leading-none text-[#FFDD15]">SWEEP</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="flex items-center gap-5">
                        {token && <>
                            <div className="flex relative items-center gap-1">
                                {/* <CartIcon2 className="w-5 h-5 fill-[#FFDD15]" /> */}
                                <img
                                    src={WeeklyChallengeIcon}
                                    alt="Weekly Challenge"
                                    className="relative cursor-pointer"
                                    onClick={openModal}
                                />
                            </div>
                            <Link to="/bonuses/level" className="newNav_onlyDesktop__d4BrB">
                                <div className="newNav_progress__SFIF_">
                                    <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: '51px', height: '50px', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px', position: "relative" }}>
                                        <img
                                            src={CrosBawIcon}
                                            decoding="async"
                                            data-nimg="fixed"
                                            style={{ position: "absolute", inset: "0px", boxSizing: "border-box", padding: "0px", border: 'none', margin: 'auto', display: 'block', width: '0px', height: '0px', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: "100%" }} />
                                    </span>
                                    <div className="newNav_levelBar__dD693">
                                        <span className="newNav_textItself__xsBoP">
                                            {activeLevel ? activeLevel.id : '1'} LEVEL
                                        </span>
                                        <div className="newNav_progress__bar__iBAcL" style={{ width: `${progress}%`, maxWidth: '100%' }}>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/cart" className="flex items-center gap-1">
                                <CartIcon2 className="w-5 h-5 fill-[#FFDD15]" />
                                <span className="text-sm font-semibold xl:font-bold xl:tracking-widest">
                                    {cartData.length ? cartData.length : 0}
                                </span>
                            </Link>
                            <div className="relative">
                                <div className="flex items-center gap-1 cursor-pointer" onClick={toggleWalletDropdown}>
                                    <WalletIcon className="w-5 h-5 fill-[#FFDD15]" />
                                    <span className="text-sm font-semibold xl:font-bold xl:tracking-widest">{main_balance + bonus_balance || 0} </span>
                                </div>
                                {walletDropdownVisible && (
                                    <div ref={walletDropdownRef} className="absolute right-0 mt-4 w-[250px] bg-black text-white rounded-lg shadow-lg p-4 border border-[#FFDD15]">
                                        <div className='flex justify-between items-center p'>
                                            <p className='font-bold'>General Wallet</p>
                                            <p className='text-xs font-semibold'>Total: ${main_balance + bonus_balance || 0}</p>
                                        </div>
                                        <div className='flex flex-col gap-3'>
                                            <div className="p-5 border-b flex justify-between items-center border-[#444] bg-[#1e1e1e] mt-4 rounded-lg">
                                                <span className="block text-sm">Main</span>
                                                <span className="block text-sm font-bold">${main_balance || 0}</span>
                                            </div>
                                            <div className="p-5 flex justify-between items-center bg-[#1e1e1e] rounded-lg">
                                                <span className="block text-sm">Bonus</span>
                                                <span className="block text-sm font-bold">${bonus_balance || 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>


                        </>}
                        {!token && (
                            <div className="flex gap-5">
                                <Link to="/login" className="tracking-widest font-bold text-sm bg-[#FFDD15] px-2 xl:px-10 py-2.5 rounded-sm text-black">SignIn</Link>
                                <Link to="/sign-up" className="tracking-widest font-bold text-sm bg-[#FFDD15] px-2 xl:px-10 py-2.5 rounded-sm text-black">Signup</Link>
                            </div>
                        )}
                        {token && (
                            <>
                                <div className="relative ">
                                    <div
                                        onClick={toggleDropdown}
                                        className="flex items-center justify-center  rounded-full cursor-pointer"
                                    >
                                        <div className=" rounded-full border-[#FFDD15] border-2 h-9 w-9 flex items-center justify-center font-bold">
                                            {userData?.username?.substring(0, 2).toUpperCase()}
                                        </div>
                                    </div>
                                    {dropdownVisible && (
                                        <div ref={dropdownRef} className="absolute right-0 mt-5 min-w-60 main-dot-bg rounded-md shadow-lg py-2 z-40 font-semibold">
                                            <div className="px-4 py-2 flex flex-col gap-2">
                                                <p>{userData.email}</p>
                                                {/* <p>{userData.username}</p> */}
                                            </div>
                                            <hr className="border-white/5 border" />
                                            <div className=''>
                                                <p className='text-center py-2 font-semibold'>Account</p>
                                                <hr className="border-white/5 border" />
                                                <Link
                                                    to="/dashboard"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    Dashboard
                                                </Link>
                                                <Link
                                                    to="/profile"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    Profile
                                                </Link>
                                                <Link
                                                    to="/deposit"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    Deposit Now
                                                </Link>
                                                <Link
                                                    to="/bonuses"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    Bonuses
                                                </Link>
                                                <Link
                                                    to="/user/deposits"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    Deposit Transaction
                                                </Link>
                                                <Link
                                                    to="/user/message/system"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    Message {notificationCount > 0 && <span className='bg-red-600 px-1.5 py-1 rounded-full'>{notificationCount}</span>}
                                                </Link>
                                                <Link
                                                    to="/user/withdrawals"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    Withdrawals
                                                </Link>
                                                <hr className="border-white/5 border" />
                                                <p className='text-center py-2 font-semibold'>Help</p>
                                                <hr className="border-white/5 border" />
                                                <Link
                                                    to="/support"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    Support
                                                </Link>
                                                <Link
                                                    to="/faq"
                                                    onClick={() => setDropdownVisible(false)}
                                                    className="block py-2 px-4 text-sm text-white hover:bg-[#0E0E0E]"
                                                >
                                                    FAQ
                                                </Link>
                                                {
                                                    token && (
                                                        <>
                                                            <hr className="border-white/5 border" />
                                                            <button
                                                                onClick={() => {
                                                                    handleLogout();
                                                                    setDropdownVisible(false);
                                                                    navigate('/login');
                                                                }}
                                                                className="block w-full px-4 text-left py-2 text-sm text-white hover:bg-[#0E0E0E]"
                                                            >
                                                                Logout
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {+notificationCount > 0 && <div className='bg-red-600 cursor-pointer relative rounded-full h-7 w-8 flex justify-center items-center' onClick={toggleDropdown}>
                                    <EmailIcon /> <span className='absolute -right-2 -top-2'>
                                        {notificationCount && notificationCount === 0
                                            ? notificationCount
                                            : ''}
                                    </span>
                                </div>}
                            </>
                        )}

                    </div>
                </div>

                {/* Second Line */}
                <div className="flex justify-center h-[60px] bg-transparent">
                    <ul className="flex gap-8 text-white h-full items-center">
                        <li className="py-5 px-6 h-full" style={{ backgroundColor: getBackgroundColor('/home') }}>
                            <Link to="/" className="flex items-center gap-1">
                                <HomeIcon className="w-5 h-5" />
                                <span className="text-sm font-semibold xl:font-bold xl:tracking-widest">Home</span>
                            </Link>
                        </li>
                        <li className="py-5 px-6 h-full" style={{ backgroundColor: getBackgroundColor('/blog') }}>
                            <Link to="/blog" className="flex items-center gap-1">
                                <BlogIcon className="w-5 h-5" />
                                <span className="text-sm font-semibold xl:font-bold xl:tracking-widest">Blog</span>
                            </Link>
                        </li>
                        <li className="py-5 px-6 h-full" style={{ backgroundColor: getBackgroundColor('/deposit') }}>
                            <Link to="/deposit" className="flex items-center gap-1">
                                <DepositIcon className="w-5 h-5" />
                                <span className="text-sm font-semibold xl:font-bold xl:tracking-widest">Deposit Now</span>
                            </Link>
                        </li>
                        <li className="py-5 px-6 h-full" style={{ backgroundColor: getBackgroundColor('/contact-us') }}>
                            <Link to="/contact-us" className="flex items-center gap-1">
                                <ContactIcon className="w-5 h-5" />
                                <span className="text-sm font-semibold xl:font-bold xl:tracking-widest">Contact Us</span>
                            </Link>
                        </li>
                        <li className="py-5 px-6 h-full" style={{ backgroundColor: getBackgroundColor('/about') }}>
                            <Link to="/about" className="flex items-center gap-1">
                                <AboutIcon className="w-5 h-5 " />
                                <span className="text-sm font-semibold xl:font-bold xl:tracking-widest">About</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>

            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/80 bg-opacity-50">
                    <div className="relative bg-black text-white rounded-lg w-[90%] md:w-[600px]">
                        {/* Close Button */}
                        <button
                            className="absolute top-2 right-2 text-white font-bold text-xl bg-transparent"
                            onClick={closeModal}
                        >
                            &times;
                        </button>


                        <div className="">
                            <div style={{
                                backgroundImage: `url(${WeeklyPopupBgImg})`,
                                height: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h2 className="text-center text-2xl font-bold mb-4 text-yellow-400 underline">
                                    WEEKLY CHALLENGE
                                </h2>
                            </div>

                            <div className="flex gap-2 p-[1rem]">

                                <div className="flex gap-2 p-[1rem] w-full">
                                    <div className="flex flex-1 flex-col gap-2">
                                        {daysOfWeek.map((day) => (
                                            <div
                                                key={day}
                                                className={`p-3 rounded-lg text-center ${today === day
                                                    ? 'bg-yellow-400 text-black font-bold'
                                                    : 'border-dashed border-yellow-400 border'
                                                    }`}
                                                aria-current={today === day ? 'date' : undefined}
                                            >
                                                {day}
                                            </div>
                                        ))}
                                    </div>

                                    <div className="flex flex-col gap-2 ">
                                        {weeklyChallengeData.length > 0 ? (
                                            weeklyChallengeData.map((challenge) => (
                                                <div
                                                    key={challenge.id}
                                                    className="bg-purple-900 p-3 rounded-lg text-center border-purple-400 border w-full"
                                                >
                                                    Make deposit and earn additional {challenge.bonus_percentage}% bonus
                                                </div>
                                            ))
                                        ) : (
                                            <div className="bg-gray-700 p-3 rounded-lg text-center text-gray-300">
                                                No current challenges
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;
