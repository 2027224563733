import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utils/AxiosInstance';
import { toast } from 'react-toastify';
import { userData } from '../../../redux/slice/authSlice';
import Loading from '../../../components/Common/Loading';

const Profile = () => {
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        phone: '',
        password: '',
        confirmPassword: '',
        referral_code: '',  // Add referral field
    });

    const { firstName, lastName, email, username, phone, password, confirmPassword, referral_code } = profileData;
    const [errors, setErrors] = useState({})

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axiosInstance.get('/get_profile.php');
                if (response.data.status.code === 1) {
                    const { first_name, last_name, email, username, phone, referral_code } = response.data.data;
                    setProfileData({
                        ...profileData,
                        firstName: first_name,
                        lastName: last_name,
                        email,
                        username,
                        phone,
                        referral_code,  // Set referral value from fetched data
                    });
                } else {
                    toast.error(response.data.status.message);
                }
            } catch (error) {
                toast.error('Failed to fetch profile data.');
                console.error('Profile fetch error:', error);
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchProfile();
        }
    }, [token]);

    const handleChange = (e) => {
        const updateErrors = { ...errors };
        if (updateErrors[e.target.name]) {
            updateErrors[e.target.name] = '';
            setErrors(updateErrors);
        }

        setProfileData({ ...profileData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setErrors({ ...errors, confirmPassword: 'Passwords and Confirm Password do not match!' });
            return;
        }

        try {
            const payload = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                username: username,
                phone: phone,
                password: password,
                confirm_password: confirmPassword,
                referral: referral_code,  // Include referral in payload
            };
            const response = await axiosInstance.post('/edit_profile.php', payload);
            if (response.data.status.code === 1) {
                toast.success('Profile updated successfully!');
                const profileResponse = await axiosInstance.get('/get_profile.php', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (profileResponse.data.status.code === 1) {
                    dispatch(userData({ user: profileResponse.data.data }));
                    navigate('/');
                } else {
                    toast.error('Failed to fetch profile data');
                }
            } else {
                toast.error(response.data.status.message);
            }
        } catch (error) {
            toast.error('An error occurred during profile update. Please try again.');
            console.error('Profile update error:', error);
        }
    };

    if (!token) {
        navigate('/login');
        return;
    }

    if(loading) return <Loading />
    return (
        <div className='mx-auto md:max-w-[70%] p-8 main-dot-bg rounded-lg'>
            <form
                onSubmit={handleSubmit}
                className="bg-[#0E0E0E] p-4 md:p-6 rounded-xl w-full "
            >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label htmlFor="firstName" className="block text-white text-base font-medium mb-2">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={firstName}
                            onChange={handleChange}
                            className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName" className="block text-white text-base font-medium mb-2">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={lastName}
                            onChange={handleChange}
                            className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                            required
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-white text-base font-medium mb-2">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="username" className="block text-white text-base font-medium mb-2">
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={handleChange}
                        className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="phone" className="block text-white text-base font-medium mb-2">
                        Phone
                    </label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={handleChange}
                        className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="referral" className="block text-white text-base font-medium mb-2">
                        Referral Code
                    </label>
                    <input
                        type="text"
                        id="referral_code"
                        name="referral_code"
                        value={referral_code}
                        onChange={handleChange}
                        disabled
                        readOnly
                        className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-white text-base font-medium mb-2">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                        className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="confirmPassword" className="block text-white text-base font-medium mb-2">
                        Confirm Password
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                        className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                        required={password ? true : false}
                    />
                    {errors.confirmPassword && <div className='text-[red] font-semibold py-2'>{errors.confirmPassword}</div>}
                </div>
                <button type="submit" className="w-full bg-yellow-500 text-black py-2 rounded-md font-semibold mt-5">
                    UPDATE
                </button>
            </form>

        </div>
    )
}

export default Profile;
