import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axiosInstance from '../../utils/AxiosInstance';
import HotGamesBg from '../../assets/image/leaderboardBg.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Title from '../Title';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HotGameData } from '../../redux/slice/authSlice';

const HotGames = () => {
  // const [games, setGames] = useState([]);
  const hotGames = useSelector((state) => state.auth.hotGames);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axiosInstance.post('get_game_list.php', { "gameID": "" });
        if (response.data.status.code === '1') {
          dispatch(HotGameData({ hotGames: response.data.data}));
          // setGames(response.data.data);
        } else {
          console.error('Failed to fetch games:', response.data.status.message);
        }
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    fetchGames();
  }, []);

  const settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 3,
    infinite: true,
    speed: 500,
    arrows: true,
    focusOnSelect: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto">
      <div
        style={{ backgroundImage: `url(${HotGamesBg})` }}
        className="flex flex-col items-center border border-[#ffdc156c] rounded-2xl pb-20 h-auto gap-5 mt-10"
      >
        <Title title="HOT" continueTitle="GAMES" />
        <div className="w-full px-3">
          <Slider {...settings} className="custom-slick-slider">
            {hotGames?.map((game) => (
              <div key={game.id} className="!flex justify-center end-image">
                <Link to={`/games/description/${game.id}`}>
                  <img
                    src={game.game_image}
                    alt={game.game_name}
                    className="game-image"
                  />
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HotGames;
