import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from '../assets/image/luckcharmsweep.png';
import Img30 from '../assets/image/30.png';
import treasureBox from '../assets/image/treasureBox.png';
import Bg from '../assets/image/popup-img-X-bg.png';
import Spinner from '../assets/image/spinner.png';
import flamingo from '../assets/image/flamingo.png';
import { Link } from 'react-router-dom';

const Popup = ({ isVisible, onClose, images, bgImg }) => {
    if (!isVisible) return null;

    const CustomPrevArrow = ({ onClick }) => (
        <div
            className="absolute bottom-4 right-14 z-10 cursor-pointer text-white h-7 flex justify-center items-center w-7 bg-[#962ed1]  rounded-md"
            onClick={onClick}
        >
            {"<"}
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div
            className="absolute bottom-4 right-4 z-10 cursor-pointer text-white h-7 flex justify-center items-center w-7 bg-[#962ed1] rounded-md"
            onClick={onClick}
        >
            {">"}
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                },
            },
        ],
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="relative w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-white text-2xl md:text-3xl font-bold z-10 bg-transparent"
                >
                    &times;
                </button>
                <div className="rounded-lg shadow-lg relative">
                    <Slider {...settings}>

                        <div className="relative h-[380px] xs:h-[350px]  overflow-hidden flex flex-col justify-center items-center text-white p-4 md:p-5">
                            <div className="absolute inset-0 bg-cover bg-center rounded-lg bg-[#23035C]"
                                style={{
                                    backgroundImage: `url(${images[6]})`,
                                }}
                            ></div>
                            <div className="text-white rounded-lg relative flex flex-col md:flex-row items-end md:items-center justify-center md:justify-end h-full">
                                <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left RammettoOne">
                                    <Link to="/home" className="flex items-center gap-1">
                                        <img src={logo} className="h-[80px] xs:h-[90px] sm:h-[100px] object-contain" alt="logo" />
                                    </Link>
                                    <p className="mt-2 tracking-wider text-[26px] xs:text-2xl sm:text-3xl text-[#FFB600]">
                                        GET <span className='text-[30px] xs:text-4xl'>$20</span>
                                    </p>
                                    <p className="mt-2 tracking-wider text-[24px] xs:text-[26px] sm:text-[28px] text-[#FFB600]">
                                        FREEPLAY
                                    </p>
                                    <div className='flex gap-5 text-base xs:text-xl items-center mt-5 justify-center md:justify-start'>
                                        BY REGISTERING AT <br /> LUCKCHARM SWEEP!
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Slide 2 */}
                        <div className="relative flex flex-col justify-end items-end text-white p-5 h-[380px] sm:h-[350px] overflow-hidden">
                            <div className="absolute inset-0 bg-cover bg-center rounded-lg bg-[#23035C]"
                                style={{
                                    backgroundImage: `url(${Bg})`,
                                }}
                            ></div>
                            <div className="text-white rounded-lg relative flex flex-col md:flex-row justify-center items-center h-full">
                                <div className='md:mr-6'>
                                    <div className="text-xl md:text-3xl font-bold">Happy Hours</div>
                                    <p className="mt-2 text-lg md:text-xl">
                                        Deposit between 6:00 and 10:00 a.m. at <br /> All Platforms to get an extra <br /> 30% match bonus!
                                    </p>
                                    <button className="mt-2 sm:mt-6 bg-[#EC29FC] text-white font-bold py-2 px-4 rounded">
                                        LEARN MORE
                                    </button>
                                </div>
                                <div>
                                    <img src={Img30} alt="" className='w-[100px] md:w-[150px] mt-5 md:mt-0 object-contain' />
                                </div>
                            </div>
                        </div>

                        {/* Slide 3 */}
                        <div className="relative flex flex-col justify-end items-end text-white p-5 h-[380px] sm:h-[350px] overflow-hidden">
                            <div className="absolute inset-0 bg-cover bg-center rounded-lg bg-[#23035C]"
                                style={{
                                    backgroundImage: `url(${Bg})`,
                                }}
                            ></div>
                            <div className="text-white rounded-lg relative flex flex-col md:flex-row justify-center items-center h-full">
                                <div className='md:mr-6 flex-1'>
                                    <div className="text-xl md:text-3xl font-bold">Quest Achievement</div>
                                    <p className="mt-2 text-lg md:text-xl">
                                        Complete quests, earn points, & redeem them
                                        as free plays by participating in our Quest
                                        Achievements Challenge.
                                    </p>
                                    <button className="mt-2 sm:mt-6 bg-[#EC29FC] text-white font-bold py-2 px-4 rounded">
                                        LEARN MORE
                                    </button>
                                </div>
                                <div className='flex-[0.3]'>
                                    <img src={treasureBox} alt="" className='w-[100px] md:w-full mt-5 md:mt-0 object-contain' />
                                </div>
                            </div>
                        </div>

                        {/* Slide 4 */}
                        <div className="relative flex flex-col justify-end items-end text-white p-5 h-[380px] sm:h-[350px] overflow-hidden">
                            <div className="absolute inset-0 bg-cover bg-center rounded-lg bg-[#23035C]"
                                style={{
                                    backgroundImage: `url(${Bg})`,
                                }}
                            ></div>
                            <div className="text-white rounded-lg relative flex flex-col md:flex-row justify-center items-center h-full">
                                <div className='md:mr-6 flex-1'>
                                    <div className="text-xl md:text-3xl font-bold">Wheel of Fortune</div>
                                    <p className="mt-2 text-lg md:text-xl">
                                        Spin the Fortune Wheel for Exclusive
                                        rewards, including free spins, deposit
                                        match bonuses, and free plays!
                                    </p>
                                    <button className="mt-2 sm:mt-6 bg-[#EC29FC] text-white font-bold py-2 px-4 rounded">
                                        LEARN MORE
                                    </button>
                                </div>
                                <div className='flex-[0.3]'>
                                    <img src={Spinner} alt="" className='w-[100px] md:w-full mt-5 md:mt-0 object-contain' />
                                </div>
                            </div>
                        </div>

                        {/* Slide 5 */}
                        <div className="relative flex flex-col justify-end items-end text-white p-5 h-[380px] sm:h-[350px] overflow-hidden">
                            <div className="absolute inset-0 bg-cover bg-center rounded-lg bg-[#23035C]"
                                style={{
                                    backgroundImage: `url(${Bg})`,
                                }}
                            ></div>
                            <div className="text-white rounded-lg relative flex flex-col md:flex-row justify-center items-center h-full">
                                <div className='md:mr-6 flex-1'>
                                    <div className="text-xl md:text-3xl font-bold">Aviator Game</div>
                                    <p className="mt-2 text-lg md:text-xl">
                                        Skyrocket Your Wins with Aviator! Thrilling
                                        gameplay, instant payouts, & many more
                                        surprises are waiting for you at
                                        Flaming07!
                                    </p>
                                    <button className="mt-2 sm:mt-6 bg-[#EC29FC] text-white font-bold py-2 px-4 rounded">
                                        LEARN MORE
                                    </button>
                                </div>
                                <div className='flex-[0.3]'>
                                    <img src={flamingo} alt="" className='w-[100px] md:w-full mt-5 md:mt-0 object-contain' />
                                </div>
                            </div>
                        </div>


                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Popup;
