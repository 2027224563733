import React, { useState } from 'react';
import axiosInstance from '../../../utils/AxiosInstance';
import SignUpBg from '../../../assets/image/signUpBg.png';
import { toast } from 'react-toastify';
import { setLevelDataState, UPDATE_BALANCE, userData, userToken } from '../../../redux/slice/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';


const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    phone: '',
    referralCode: '',
    platform: '',
    password: '',
    confirmPassword: '',
    age: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password confirmation validation
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match!');
      return;
    }

    // Construct the payload
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      username: formData.username,
      phone: formData.phone,
      referral_code: formData.referralCode, // Include the referral code in payload
      platform: formData.platform,
      password: formData.password,
      confirm_password: formData.confirmPassword,
    };

    try {
      const response = await axiosInstance.post('/signup.php', payload);

      if (response.data.status.code === 1) {
        toast.success(response.data.status.message);
        const { token } = response.data.data;

        dispatch(userToken({ token }));

        // Fetch bounus level data
        try {
          const response = await axiosInstance.post('/bounus_level.php');
          if (response.data && response.data.status.code === 1) {
            const enrichedData = response.data.data; // Assume enrichData is a function to process data
            dispatch(setLevelDataState(enrichedData));
          } else {
            console.error('Failed to fetch bonus levels: Invalid response');
          }
        } catch (error) {
          console.error('Failed to fetch bonus levels:', error);
        }

        // Fetch user profile data
        const profileResponse = await axiosInstance.get('/get_profile.php', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (profileResponse.data.status.code === 1) {
          dispatch(userData({ user: profileResponse.data.data }));
          navigate('/deposit');
        } else {
          toast.error('Failed to fetch profile data');
        }

        // Get User Balacnce
        try {
          const response = await axiosInstance.get('/user_balance.php', {
            headers: { Authorization: `Bearer ${token}` },
          });
          const { main_balance, bonus_balance, total_deposit } = response.data.data;

          // Dispatch the action to update the balance
          dispatch(UPDATE_BALANCE({ main_balance, bonus_balance, total_deposit }));
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error(response.data.status.message);
        setFormData({
          ...formData,
          email: '',
          phone: '',
          password: '',
          confirmPassword: '',
        });
      }
    } catch (error) {
      console.error('Signup error:', error);
      toast.error('An error occurred during signup. Please try again.');

      // Clear specific fields in case of an error
      setFormData({
        ...formData,
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
      });
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${SignUpBg})`,
      }}
      className='min-h-screen bg-cover'
    >
      <div className='container mx-auto px-4 md:px-8'>
        <div className='pt-16 md:pt-32'>
          <h1 className='flex font-bold justify-center items-center text-[#FFDD15] text-2xl md:text-4xl underline bg-cover'>
            REGISTER
          </h1>
          <div className='flex justify-center items-center flex-col text-white text-lg md:text-3xl font-bold uppercase mt-6 md:mt-10'>
            <p>Earn up to $20</p>
            <p className='text-center'>by Registering at Bitplay Casino!</p>
          </div>
        </div>

        <div className='flex justify-center mt-8 md:mt-10 pb-10'>
          <form onSubmit={handleSubmit} className='bg-[#0E0E0E] p-4 md:p-6 rounded-xl w-full md:w-2/3 lg:w-1/2'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
              <div>
                <label htmlFor='firstName' className='block text-white text-sm mb-2'>
                  First Name
                </label>
                <input
                  type='text'
                  id='firstName'
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleChange}
                  className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                  required
                />
              </div>
              <div>
                <label htmlFor='lastName' className='block text-white text-sm mb-2'>
                  Last Name
                </label>
                <input
                  type='text'
                  id='lastName'
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleChange}
                  className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                  required
                />
              </div>
            </div>
            <div className='mb-4'>
              <label htmlFor='email' className='block text-white text-sm mb-2'>
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='username' className='block text-white text-sm mb-2'>
                Username
              </label>
              <input
                type='text'
                id='username'
                name='username'
                value={formData.username}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='phone' className='block text-white text-sm mb-2'>
                Phone
              </label>
              <input
                type='tel'
                id='phone'
                name='phone'
                value={formData.phone}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='age' className='block text-white text-sm mb-2'>
                Age
              </label>
              <input
                type='number'
                id='age'
                name='age'
                value={formData.age}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
                max={100}
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='referralCode' className='block text-white text-sm mb-2'>
                Referral Code
                <span
                  className="ml-2 relative cursor-pointer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <FaInfoCircle className="inline text-gray-400 hover:text-gray-200 font-bold w-4 h-4" color='white' />
                  {/* Tooltip */}
                  {showTooltip && (
                    <div
                      className="absolute left-0 mt-1 w-72 bg-gray-700 text-gray-200 text-[0.889rem] rounded-lg shadow-lg p-3 z-10 font-bold"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      If you use your friend's referral code and deposit at least $20, both of you will be granted $20 worth of free credits
                    </div>
                  )}
                </span>
              </label>
              <input
                type='text'
                id='referralCode'
                name='referralCode'
                value={formData.referralCode}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='password' className='block text-white text-sm mb-2'>
                Password
              </label>
              <input
                type='password'
                id='password'
                name='password'
                value={formData.password}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>
            <div className='mb-6'>
              <label htmlFor='confirmPassword' className='block text-white text-sm mb-2'>
                Confirm Password
              </label>
              <input
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                value={formData.confirmPassword}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>
            <button type='submit' className='w-full bg-yellow-500 text-black py-2 rounded-md font-semibold'>
              NEXT
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
