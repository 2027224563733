import React from 'react';
import { useNavigate } from 'react-router-dom';
import TestimonialBg from '../../assets/image/testimonials.png';

const MainContent = ({ gameDetails }) => {
    const navigate = useNavigate();

  

    return (
        <div className='container mx-auto flex justify-center items-center'>
            <div
                style={{ backgroundImage: `url(${TestimonialBg})` }}
                className="flex flex-col items-center border border-[#ffdc156c] rounded-2xl h-auto gap-5 mt-10 md:mb-40"
            >
                <div className='flex justify-center items-center px-5 py-5 flex-col'>
                    <div className='flex items-center flex-col md:flex-row justify-center gap-5'>
                        <div className='w-[200px] md:w-[25%] flex items-center justify-center'>
                            <img src={gameDetails.game_image} alt={gameDetails.game_name} className='w-full object-container' />
                        </div>
                        <div className='text-white flex flex-col gap-3 w-full md:w-[70%]'>
                            <h1 className='font-bold text-3xl uppercase'>{gameDetails.game_name}</h1>
                            <p className='text-[#CACACA]'>{gameDetails.game_description.replace(/\\r\\n/g, ' ')}</p>
                            <div className='mt-3'>
                                <button
                                    className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base"
                                    onClick={() => navigate('/deposit')}
                                >
                                    DEPOSIT NOW
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* <div className='mt-16 mb-10 flex flex-wrap gap-5'>
                        {gameDetails.additional_images && gameDetails.additional_images.map((img, index) => (
                            <img key={index} src={img} className='w-[350px]' alt={`Game Image ${index}`} />
                        ))}
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default MainContent;
