import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Common/Loading";

const FreePlayForm = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [platform, setPlatform] = useState("");
  const [applyStatus, setApplyStatus] = useState(null);
  const [games, setGames] = useState([]);
  const [couponBalance, setCouponBalance] = useState(""); // State for coupon balance
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axiosInstance.post("/get_game_list.php", {
          gameID: ""
        });
        setGames(response.data.data);
      } catch (error) {
        console.error("Error fetching game list:", error);
      }
    };

    fetchGames();
  }, []);

  const handleApplyCode = async () => {
    try {
      const response = await axiosInstance.post('/verify_coupons_code.php', {
        coupons_code: code,
      });

      if (response?.data?.status?.code === 1) {
        toast.success("Free Play code applied successfully! Enjoy your rewards.");
        setApplyStatus('Code applied successfully!');
        setCouponBalance(response.data.data.coupon_balance); // Set coupon balance
      } else {
        setApplyStatus(response?.data?.status.message);
        toast.error(response?.data?.status.message);
      }
    } catch (error) {
      console.error("Error applying code:", error);
      setApplyStatus('Error applying the code. Please try again.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await axiosInstance.post('/free_play_coupon_bonus.php', {
        coupons_code: code,
        coupon_balance: couponBalance, // Include coupon balance in the payload
        platform: platform,
      });

      if (response?.data?.status?.code === 1) {
        toast.success('Bonus applied successfully!');
        navigate('/bonuses/level')
      } else {
        toast.error('Error applying the bonus. Please try again.');
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApplyStatus('Error submitting the form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="h-full p-4 sm:p-6 rounded-lg w-full mx-auto flex justify-center items-start"
    >
      <div className="flex main-dot-bg p-4 sm:p-7 rounded-md flex-col w-full sm:w-3/4 md:w-4/5 lg:w-[60%] lgs:w-1/2">

        {/* Display coupon balance after applying the code */}
        {couponBalance && (
          <div className="mb-4 bg-[#290A47] p-3 rounded-md text-yellow-400 text-center font-semibold">
            Coupon Balance: {couponBalance}
          </div>
        )}

        <div className="mb-4">
          <label htmlFor="code" className="block text-white mb-2 font-semibold">
            FREE-PLAY CODE *
          </label>
          <div className="flex flex-col sm:flex-row">
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="CODE"
              required
              className="flex-grow p-2 mb-2 sm:mb-0 sm:mr-2 rounded-md bg-[#0E0E0E] main-border text-sm text-white placeholder:text-white focus:outline-none outline-none"
            />
            <button
              type="button"
              onClick={handleApplyCode}
              className="p-2 bg-[#290A47] text-white rounded-md font-semibold text-base"
              style={{ borderWidth: '1px', borderColor: 'rgba(255, 255, 255, 0.16)' }}
            >
              Apply code
            </button>
          </div>
          {applyStatus && (
            <p className={`mt-2 text-sm ${applyStatus.includes('successfully') ? 'text-green-400' : 'text-red-400'}`}>
              {applyStatus}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="platform" className="block text-white mb-2 font-semibold">
            Platform *
          </label>
          <select
            id="platform"
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            required
            className="w-full p-3 mb-2 rounded-md bg-[#0E0E0E] main-border text-sm text-white placeholder:text-white focus:outline-none outline-none"
          >
            <option value="">Select Game</option>
            {games.map((game) => (
              <option key={game.id} value={game.id}>
                {game.game_name}
              </option>
            ))}
            <option value="bonus">Bonus</option>
          </select>
        </div>

        <button
          type="submit"
          className="w-full p-2 bg-[#FFDD15] mt-3 text-black font-semibold text-base rounded-md"
          disabled={!platform}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default FreePlayForm;
