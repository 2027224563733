import React from 'react'
import Title from '../Title'
import ourProgramBg from '../../assets/image/our-progrmas.png'

const HowItWorks = () => {
    return (
        <div className='mt-12'>
            <Title title={"HOW IT "} continueTitle={"WORKS"} />
            {/* <div className='flex items-center justify-center gap-6 mt-10 flex-wrap'> */}
            <div className="flex justify-center">
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-10 place-items-center'>
                    <div style={{
                        backgroundImage: `url(${ourProgramBg})`,
                    }} className='flex flex-col gap-5 items-center justify-center border border-[#FFDD15] py-3 px-3 pt-5 text-xl rounded-2xl h-[200px] w-[250px]'>
                        <p className='text-white text-xl font-semibold'>Stage 1</p>
                        <p className='text-[#CACACA] h-full text-center'>Complete The Player <br />Registration Form</p>
                    </div>
                    <div style={{
                        backgroundImage: `url(${ourProgramBg})`,
                    }} className='flex flex-col gap-5 items-center justify-center border border-[#FFDD15] py-3 px-3 pt-5 text-xl rounded-2xl h-[200px] w-[250px]'>
                        <p className='text-white text-xl font-semibold'>Stage 2</p>
                        <p className='text-[#CACACA] h-full text-center'>Download Your <br />Favourite Milky Way <br />App Now!</p>
                    </div>
                    <div style={{
                        backgroundImage: `url(${ourProgramBg})`,
                    }} className='flex flex-col gap-5 items-center justify-center border border-[#FFDD15] py-3 px-3 pt-5 text-xl rounded-2xl h-[200px] w-[250px]'>
                        <p className='text-white text-xl font-semibold'>Stage 3</p>
                        <p className='text-[#CACACA] h-full text-center'>You Are Almost <br />There</p>
                    </div>
                    <div style={{
                        backgroundImage: `url(${ourProgramBg})`,
                    }} className='flex flex-col gap-5 items-center justify-center border border-[#FFDD15] py-3 px-3 pt-5 text-xl rounded-2xl h-[200px] w-[250px]'>
                        <p className='text-white text-xl font-semibold'>Stage 4</p>
                        <p className='text-[#CACACA] h-full text-center'>Complete The Player <br />Registration Form</p>
                    </div>
                    <div style={{
                        backgroundImage: `url(${ourProgramBg})`,
                    }} className='flex flex-col gap-5 items-center justify-center border border-[#FFDD15] py-3 px-3 pt-5 text-xl rounded-2xl h-[200px] w-[250px]'>
                        <p className='text-white text-xl font-semibold'>Stage 5</p>
                        <p className='text-[#CACACA] h-full text-center'>Download Your <br />Favourite Milky Way <br />App Now!</p>
                    </div>
                    <div style={{
                        backgroundImage: `url(${ourProgramBg})`,
                    }} className='flex flex-col gap-5 items-center justify-center border border-[#FFDD15] py-3 px-3 pt-5 text-xl rounded-2xl h-[200px] w-[250px]'>
                        <p className='text-white text-xl font-semibold'>Stage 6</p>
                        <p className='text-[#CACACA] h-full text-center'>You Are Almost <br />There</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks