import React, { useState, useEffect } from 'react';
import HeroBox from '../HeroBox';
import BgImage from '../../assets/image/hero-bg.png';
import Hero1 from '../../assets/image/hero-1.png';
import Slider from 'react-slick';
import axiosInstance from '../../utils/AxiosInstance';

const Index = () => {
    const [recentWinners, setRecentWinners] = useState([]);
    const [loading, setLoading] = useState(true);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };


    useEffect(() => {
        const fetchRecentWinners = async () => {
            try {
                const response = await axiosInstance.post('/get_recent_winner.php');
                setRecentWinners(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching recent winners:', error);
                setLoading(false);
            }
        };

        fetchRecentWinners();
    }, []);

    return (
        <div
            style={{
                backgroundImage: `url(${BgImage})`,
            }}
            className="flex items-center justify-center min-h-screen bg-cover w-full bg-top"
        >
            <div className="flex gap-12 flex-col lgs:flex-row mt-6">
                <HeroBox title={"Register and get $20 WELCOME BONUS"}>
                    <Slider {...settings} className="custom-slick-slider">
                        <div>
                            <img className="h-full w-full object-cover rounded-2xl" src={Hero1} alt="hero1" />
                        </div>
                        <div>
                            <img className="h-full w-full object-cover rounded-2xl" src={Hero1} alt="hero2" />
                        </div>
                        <div>
                            <img className="h-full w-full object-cover rounded-2xl" src={Hero1} alt="hero3" />
                        </div>
                    </Slider>
                </HeroBox>

                <HeroBox title={"Recent Winners"}>
                    <div className="infinite-scroll">
                        <div className="infinite-scroll-content w-full">
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                recentWinners.length > 0 ? (
                                    // Repeat the recent winners list twice for continuous scrolling
                                    [...recentWinners, ...recentWinners].map((winner, index) => (
                                        <div key={`${winner.id}-${index}`} className="flex bg-[#222222] justify-between px-4 pb-2 w-full items-center">
                                            <span className="winner-email bg-[#ffffff] bg-opacity-20 text-white h-fit px-1 py-1 rounded-sm text-xs sm:text-base">
                                                {winner.email}
                                            </span>
                                            <span className="winner-amount bg-[#ffffff] bg-opacity-20 text-white h-fit px-5 py-1 font-semibold rounded-sm text-xs sm:text-base">
                                                ${winner.amount}
                                            </span>
                                            <img src={winner.game_image} alt="winner-img" className="winner-image rounded-full object-cover" />
                                        </div>
                                    ))
                                ) : (
                                    <div>No recent winners found</div>
                                )
                            )}
                        </div>
                    </div>
                </HeroBox>

            </div>
        </div>
    );
};

export default Index;
