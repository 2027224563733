import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../utils/AxiosInstance';
import { setLevelDataState, UPDATE_BALANCE } from '../../../redux/slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/Common/Loading';

const BonusesLevel = () => {
    const [levelData, setLevelData] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const total_deposit = useSelector((state) => state.auth.total_deposit); // Access balance state


    useEffect(() => {
        const fetchBonusLevelData = async () => {
            try {
                const response = await axiosInstance.post('/bounus_level.php');
                if (response.data && response.data.status.code === 1) {
                    let transformedData = response.data.data.map((item) => ({
                        ...item,                    // Retain original properties
                        currentActive: false,       // Add default currentActive property
                        progress: '0',          // Add progressBar property
                        progressStatus: false       // Add progressStatus property
                    }));

                    // Step 1: Filter active items and reverse the array
                    const activeItems = transformedData
                        .filter((item) => item.active === true)
                        .reverse();

                    // Step 2: Update transformedData to mark the first active item
                    let currentActiveIndex = null;
                    transformedData = transformedData.map((item, index) => {
                        // Check if the current item's id exists in activeItems
                        const matchingActiveItem = activeItems.find((activeItem) => activeItem.id === item.id);

                        // If a matching active item is found, update currentActive accordingly
                        if (matchingActiveItem && matchingActiveItem.id === activeItems[0].id) {
                            currentActiveIndex = index; // Store the index of the first active item
                            return {
                                ...item,
                                currentActive: true
                            };
                        }

                        return {
                            ...item,
                            currentActive: false
                        };
                    });

                    // Step 3: Update progressStatus and progress for the next object after currentActive
                    transformedData = transformedData.map((item, index) => {
                        if (currentActiveIndex !== null && index === currentActiveIndex + 1) {
                            const currentActiveItem = transformedData[currentActiveIndex];

                            const validMainBalance = !total_deposit || total_deposit === 0
                                ? 1
                                : total_deposit;

                            // Calculate the progress based on currentActive's max_deposit_limit and main_balance
                            const progress = Math.round((validMainBalance / currentActiveItem.max_deposit_limit) * 100);

                            return {
                                ...item,
                                progress: progress > 100 ? '100' : progress.toString(), // Ensure progress doesn't exceed 100
                                progressStatus: true // Set progressStatus to true for the next item after currentActive
                            };
                        }
                        return {
                            ...item,
                            progressStatus: false // All other items have progressStatus: false
                        };
                    });


                    // Step 4: Set `active: true` for all previous items before currentActive
                    transformedData = transformedData.map((item, index) => {
                        if (currentActiveIndex !== null && index < currentActiveIndex) {
                            return {
                                ...item,
                                active: true // Set active to true for all previous items before currentActive
                            };
                        }
                        return item;
                    });

                    // Enrich the transformedData before setting it to the state
                    const enrichedData = enrichData(transformedData);
                    setLevelData(enrichedData);
                    dispatch(setLevelDataState(response.data.data));
                } else {
                    console.error('Failed to fetch bonus levels: Invalid response');
                }
            } catch (error) {
                console.error('Failed to fetch bonus levels:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBonusLevelData();
    }, [total_deposit]);

    // Function to enrich the API data with background and filter
    const enrichData = (apiData) => {
        return apiData.map(item => {
            const match = DamylevelData.find(d => d.id === item.id);
            return match ? { ...item, background: match.background, filter: match.filter } : item;
        });
    };

    useEffect(() => {
        getGeneralWalletData();
    }, []);

    const getGeneralWalletData = async () => {
        try {
            const response = await axiosInstance.get('/user_balance.php');
            const { main_balance, bonus_balance, total_deposit } = response.data.data;

            // Dispatch the action to update the balance
            dispatch(UPDATE_BALANCE({ main_balance, bonus_balance, total_deposit }));
        } catch (error) {
            console.log(error);
        }
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="p-4">
            <div className="flex flex-wrap gap-3 items-center justify-center lg:justify-start xl:justify-between">
                {
                    levelData.map(data => (
                        <div key={data.id} className="flex flex-col items-center justify-start rounded w-full sm:w-[48%] lg:w-[31%] xl:w-[24%] h-full pt-3 bg-transparent" style={{
                            border: data.active ? '1px solid rgb(239, 202, 57)' : '1px solid rgb(48, 52, 69)',
                            borderRadius: '10px'
                        }}>
                            <div className='text-lg uppercase font-extrabold px-3' style={{ color: 'rgba(255, 255, 255, 0.92)' }}>{data.title}
                            </div>

                            <div className='css-1cpa8wi'>
                                <div className='inline-block p-[25px] rounded-[12px]' style={{ background: data.background }}>
                                    <img
                                        src={data.image}
                                        alt={data.title}
                                        className='w-[114px] max-w-[200px] h-[130px] max-h-[120px] object-contain'
                                        style={{ filter: data.active ? 'none' : data.filter }}
                                    />
                                    {
                                        !data.active &&
                                        (
                                            <div className="css-194gi2f" style={{ transform: "translate(-50%, -50%)" }}>
                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" fontSize="38" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"></path>
                                                </svg>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className='flex flex-col justify-between h-full w-full' style={{ borderTop: '1px solid rgb(48, 52, 69)', color: 'rgba(255, 255, 255, 0.92)' }}>

                                <div className='py-[16px] px-3'>
                                    <div className='flex items-center justify-between mb-[0.75rem] text-center'>
                                        <p className='font-semibold text-[1rem]'>
                                            Daily withdrawal Limit
                                        </p>
                                        <p className='font-semibold text-[15px] px-3' style={{ color: 'rgb(169, 183, 217)' }}>
                                            {data.withdrawal_limit ? `$${data.withdrawal_limit}` : ''}
                                        </p>
                                    </div>
                                    {data.progressStatus && (
                                        <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700 mb-2">
                                            <div class="progressBar text-xs font-medium text-center p-[1px] leading-none rounded-full" style={{ width: `${data.progress}%`, maxWidth: '100%' }}> {data.progress}%</div>
                                        </div>
                                    )}
                                    <div className='flex items-center justify-between mb-[0.75rem] text-center'>
                                        <p className='font-semibold text-[1rem]'>
                                            Total Deposit
                                        </p>
                                        <p className='font-semibold text-[15px] px-3' style={{ color: 'rgb(169, 183, 217)' }}>
                                            {data.min_deposit_limit ? `$${data.min_deposit_limit} -` : ''} &nbsp;
                                            {data.max_deposit_limit ? `$${data.max_deposit_limit}` : ''}
                                        </p>
                                    </div>
                                </div>

                                <div className="css-yp6gff">
                                    <p>
                                        DEPOSIT BONUS {data.bonus ? data.bonus : '0.0'}%
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default BonusesLevel;

const DamylevelData = [
    {
        id: '1',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(215, 98, 69, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '2',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(215, 98, 69, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '3',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(215, 98, 69, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '4',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(215, 98, 69, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '5',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(191, 191, 191, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '6',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(191, 191, 191, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '7',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(191, 191, 191, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '8',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(191, 191, 191, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '9',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(252, 149, 30, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '10',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(252, 149, 30, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '11',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(252, 149, 30, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '12',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(252, 149, 30, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '13',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(206, 241, 251, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '14',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(206, 241, 251, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '15',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(206, 241, 251, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '16',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(206, 241, 251, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '17',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(206, 241, 251, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '18',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(206, 241, 251, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '19',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(206, 241, 251, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    },
    {
        id: '20',
        filter: 'brightness(0.5)',
        background: 'radial-gradient(circle, rgba(206, 241, 251, 0.8) 0%, rgba(0, 0, 0, 0) 67%)'
    }
]

