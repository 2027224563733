import React from 'react'
import Hero from '../../../components/Hero'
import GamesDescriptionBg from '../../../assets/image/gamesDescriptionBg.png'
import MilkyWayCasino from '../../../components/gamesDescription/MilkyWayCasino'

const MilkyWayCasinoGame = () => {
    return (
        <>
            <Hero title={"MILKY WAY CASINO"} bgImg={GamesDescriptionBg} backgroundColor='#290A47' />
            <MilkyWayCasino />
        </>
    )
}

export default MilkyWayCasinoGame