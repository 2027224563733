import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../../components/Common/Loading";

const AllPlatform = () => {
    const navigate = useNavigate();
    const [gameData, setGameData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // New state to store filtered data
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { total_deposit } = useSelector(state => state.auth);
    const modalRef = useRef(); // Create a ref to track the modal

    // Fetch the game account data once when the component mounts
    const fetchMessages = async () => {
        try {
            const response = await axiosInstance.get('/get_platform_game_account_list.php');
            if (response.data.status.code === '1') {
                setGameData(response.data.data);

                // Filter data: only where is_requested === 2 and username, password are not null
                const filtered = response.data.data.filter(
                    item => item.is_requested === "2" && item.username && item.password
                );
                setFilteredData(filtered); // Set the filtered data
            } else {
                toast.error("Failed to fetch platform accounts");
            }
        } catch (error) {
            console.error('Error fetching platform accounts:', error);
        } finally {
            setLoading(false);
        }
    };

    // Ensure the API call only happens once
    useEffect(() => {
        fetchMessages();
    }, []);

    // Handle the reset click action based on the item's status
    const handleResetClick = (item) => {
        if (item.is_requested === "1" || item.is_requested === "2") {
            toast.error("Request for this game has already been submitted");
        } else if (item.is_requested === "0") {
            if (parseFloat(total_deposit) >= 20) {
                handleConfirmReset(item);
            } else {
                setIsModalOpen(true);
                return;
            }
        }
    };

    // Handle the password reset confirmation request
    const handleConfirmReset = async (item) => {
        try {
            const response = await axiosInstance.post('/platform_game_account.php', { gameID: item.id });
            if (response?.data?.status.code === 1) {
                setGameData((prevData) =>
                    prevData.map((game) =>
                        game.id === item.id ? { ...game, is_requested: "1" } : game
                    )
                );
                toast.success(response?.data?.status.message);
                setIsModalOpen(false);
            } else {
                toast.error(response?.data?.status.message);
            }
        } catch (error) {
            console.error('Error during password reset request:', error);
            toast.error("Error submitting request");
        }
    };

    // Handle closing the deposit modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        navigate('/deposit');
    };

    // Detect outside clicks to close the modal
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false); // Close modal when clicking outside
            }
        };

        if (isModalOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isModalOpen]);


    // Show loading state if data is still being fetched
    if (loading) {
        return <Loading />;
    }

    return (
        <div className="container mx-auto p-6 bg-[#222222] rounded-lg shadow-lg">
            {
                filteredData.length > 0 && (
                    <div className="mb-6">
                        <h2 className="text-2xl font-bold text-white mb-4">Platform Accounts</h2>
                        <div className="flex flex-col text-base font-semibold">
                            <div className="flex flex-row justify-between items-center p-4 text-white">
                                <p>Platform</p>
                                <p>Username</p>
                                <p>URL</p>
                                <p>Password</p>
                            </div>
                            {
                                filteredData?.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`flex flex-row justify-between items-center p-4 text-white bg-[#1F2937] rounded-md main-border`}
                                    >
                                        <p>{item.game_name}</p>
                                        <p>{item.username ? item.username : 'Not Provide'}</p>
                                        <p>{item.url}</p>
                                        <p>{item.password ? item.password : 'Not Provide'}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            <h2 className="text-2xl font-bold text-white mb-4">All Platforms</h2>

            <div className="flex flex-col text-base font-semibold">
                <div className="flex flex-row justify-between items-center p-4 text-white">
                    <p>Name</p>
                    <p>Status</p>
                </div>
                {
                    gameData?.map((item, index) => (
                        <div
                            key={index}
                            className={`flex flex-row justify-between items-center  p-4 text-white rounded-md ${index % 2 === 0 ? 'bg-[#290A47] main-border' : 'bg-transparent'}`}
                        >
                            <p>{item.game_name}</p>
                            {item.is_requested === "2" ? (
                                <button
                                    className="text-[#4AFFA9] py-1 px-4 rounded-[10px] main-border"
                                    style={{ backgroundColor: 'rgba(74, 255, 169, 0.2)' }}
                                >
                                    Created
                                </button>
                            ) : item.is_requested === "1" ? (
                                <button
                                    className="text-[#F8924F] py-1 px-4 rounded-[10px] main-border"
                                    style={{ backgroundColor: 'rgba(248, 146, 79, 0.2)' }}
                                >
                                    Pending
                                </button>
                            ) : (
                                <button
                                    className="text-white py-1 px-4 rounded-[10px] main-border"
                                    style={{ backgroundColor: 'rgba(255, 43, 98, 1)' }}
                                    onClick={() => handleResetClick(item)}
                                >
                                    Request
                                </button>
                            )}
                        </div>
                    ))
                }
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
                    <div ref={modalRef} className="bg-white text-black p-5 rounded-lg shadow-lg max-w-[35%]">
                        <h2 className="text-xl mb-4 text-center font-bold">Deposit now!</h2>
                        <p>
                            For your request to be approved, you must make a minimum deposit ($20) on this platform. The deposit made will be uploaded to the platform of your choice.
                        </p>
                        <div className="mt-4 flex justify-center space-x-3">
                            <button
                                onClick={handleCloseModal}
                                className="px-4 py-2 bg-[#7066e0] text-white rounded-md"
                            >
                                Make a new deposit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AllPlatform;
