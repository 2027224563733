import React from 'react'
import { Link } from 'react-router-dom'
import BgFooterImage from '../../assets/image/footer_main.png'
import logo from "../../assets/image/logo.png"
import { AndroidIcon, BitcoinIcon, FacebookIcon, InstagramIcon, QuoraIcon, RedditIcon, TelegramIcon, TwentyoneIcon, TwitterIcon } from '../../utils/Icons'

const Footer = () => {
    return (
        <>
            <div className="relative h-full w-full py-10">
                <div
                    style={{
                        backgroundImage: `url(${BgFooterImage})`,
                    }}
                    className="absolute inset-0 h-full w-full bg-cover bg-bottom bg-no-repeat opacity-20"
                ></div>
                <div className="relative z-10">
                    <div className='container mx-auto'>
                        <div className='flex items-start justify-between flex-wrap gap-10'>
                            <div className='text-white  w-full md:w-auto'>
                                <Link to="/home" className='flex justify-start gap-1 p-4'>
                                    <img src={logo} className="h-[38.5] w-10 object-contain" alt='logo' />
                                    <div className='flex flex-col gap-1'>
                                        <h1 className='leading-none text-white'>Lucky Charm</h1>
                                        <h2 className='leading-none text-[#FFDD15]'>SWEEP</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className='text-white w-full md:w-auto'>
                                <ul className='flex flex-col gap-3 px-6 font-extrabold'>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/deposit">Deposit Now</Link></li>
                                </ul>
                            </div>
                            <div className='text-white w-full md:w-auto'>
                                <ul className='flex flex-col gap-3 px-6 font-extrabold'>
                                    <li><Link to="/faq">FAQ</Link></li>
                                    <li><Link to="/contact-us">Contact</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-of-service">Terms of Service</Link></li>
                                    <li><Link to="/referral/program">Referrals Program</Link></li>
                                </ul>
                            </div>
                            <div className='text-white'>
                                <ul className='flex flex-col gap-3 px-6 font-extrabold'>
                                    <div className='flex gap-6'>
                                        <Link to={"#"}>
                                            <TwentyoneIcon />
                                        </Link>
                                        <Link to={"#"}>
                                            <BitcoinIcon />
                                        </Link>
                                    </div>
                                    <div className='flex flex-wrap gap-3'>
                                        <Link to={"#"}>
                                            <FacebookIcon />
                                        </Link>
                                        <Link to={"#"}>
                                            <InstagramIcon />
                                        </Link>
                                        <Link to={"#"}>
                                            <TwitterIcon />
                                        </Link>
                                        <Link to={"#"}>
                                            <TelegramIcon />
                                        </Link>
                                        <Link to={"#"}>
                                            <QuoraIcon />
                                        </Link>
                                        <Link to={"#"}>
                                            <RedditIcon />
                                        </Link>
                                        <Link to={"#"}>
                                            <AndroidIcon />
                                        </Link>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
