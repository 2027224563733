import axios from 'axios';
import { store } from '../redux/store';

const axiosInstance = axios.create({
    // baseURL: 'https://luckcharmsweep.workbudy.info/api/v2/dfjfngjn/',
    baseURL: 'https://api.luckycharmsweep.com/api/v2/dfjfngjn/',
    timeout: 500000,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const state = store.getState();
        const token = state.auth.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
