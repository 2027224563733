import React, { useEffect, useState } from 'react';
import Popup from '../../../components/Popup';
import PopupBg from '../../../assets/image/popup-bg.webp';
import PopupImg1 from '../../../assets/image/popup-img-1.png';
import PopupImg2 from '../../../assets/image/popup-img-2.png';
import PopupImg3 from '../../../assets/image/popup-img-3.webp';
import PopupImg4 from '../../../assets/image/popup-img-4.webp';
import PopupImg5 from '../../../assets/image/popup-img-5.webp';
import PopupImg6 from '../../../assets/image/popup-img-6.webp';
import PopupImgX from '../../../assets/image/popup-img-X.png';
import HomeComp from '../../../components/home/index';
import HotGames from '../../../components/home/HotGames';
import OurPlatforms from '../../../components/home/OurPlatforms';
import HowItWorks from '../../../components/home/HowItWorks';
import Testimonials from '../../../components/home/Testimonials';
import JoinToday from '../../../components/home/JoinToday';

const Home = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    setPopupVisible(true);
  }, []);

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const images = [PopupImg1, PopupImg2, PopupImg3, PopupImg4, PopupImg5, PopupImg6, PopupImgX];

  return (
    <>
      <Popup
        isVisible={isPopupVisible}
        onClose={handleClosePopup}
        images={images}
        bgImg={PopupBg}
      />

      <HomeComp />
      <HotGames />
      <OurPlatforms />
      <HowItWorks />
      <Testimonials />
      <JoinToday />
    </>
  );
};

export default Home;
