import React from 'react';
import Title from '../Title';
import TestimonialBg from '../../assets/image/testimonials.png';
import TestimonialAvatar from '../../assets/image/testimonialAvatar.png';
import StarRatings from 'react-star-ratings';

const Testimonials = () => {
    return (
        <div className='mt-16 pb-1' style={{
            backgroundImage: `url(${TestimonialBg})`,
        }}>
            <div className='container mx-auto'>
                <Title title={"A GAMBLE WORTH TALKING, ACCORDING TO OUR"} continueTitle={"SATISFIED PLAYERS"} br={true} />
                <div className='flex gap-10 md:gap-5  justify-between items-center my-20 flex-col md:flex-row'>
                    <div className='border border-[#3C3C3C] bg-black relative flex justify-center items-center flex-col w-[305px] rounded-md px-10'>
                        <img className='absolute w-20 rounded-full -top-6' src={TestimonialAvatar} alt="testimonialAvatar" />
                        <p className='mt-16 text-[#FFDD15] font-bold text-lg'>Oliver Queen</p>
                        <p className='text-[#4D4C44] font-bold'>Exclusive Bonuses</p>
                        <p className='text-white text-center text-sm leading-4 mt-1'>Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, </p>

                        <div className='flex items-center justify-center gap-2 pt-10 pb-5'>
                            <StarRatings
                                rating={4}
                                starRatedColor="gold"
                                numberOfStars={5}
                                starDimension="20px"
                                starSpacing="5px"
                                name='rating'
                            />
                            <span className='text-white mt-1'>7.6/10</span>
                        </div>
                    </div>
                    <div className='border border-[#3C3C3C] bg-black relative flex justify-center items-center flex-col w-[305px] rounded-md px-10'>
                        <img className='absolute w-20 rounded-full -top-6' src={TestimonialAvatar} alt="testimonialAvatar" />
                        <p className='mt-16 text-[#FFDD15] font-bold text-lg'>Oliver Queen</p>
                        <p className='text-[#4D4C44] font-bold'>Exclusive Bonuses</p>
                        <p className='text-white text-center text-sm leading-4 mt-1'>Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, </p>

                        <div className='flex items-center justify-center gap-2 pt-10 pb-5'>
                            <StarRatings
                                rating={4}
                                starRatedColor="gold"
                                numberOfStars={5}
                                starDimension="20px"
                                starSpacing="5px"
                                name='rating'
                            />
                            <span className='text-white mt-1'>7.6/10</span>
                        </div>
                    </div>
                    <div className='border border-[#3C3C3C] bg-black relative flex justify-center items-center flex-col w-[305px] rounded-md px-10'>
                        <img className='absolute w-20 rounded-full -top-6' src={TestimonialAvatar} alt="testimonialAvatar" />
                        <p className='mt-16 text-[#FFDD15] font-bold text-lg'>Oliver Queen</p>
                        <p className='text-[#4D4C44] font-bold'>Exclusive Bonuses</p>
                        <p className='text-white text-center text-sm leading-4 mt-1'>Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, </p>

                        <div className='flex items-center justify-center gap-2 pt-10 pb-5'>
                            <StarRatings
                                rating={4}
                                starRatedColor="gold"
                                numberOfStars={5}
                                starDimension="20px"
                                starSpacing="5px"
                                name='rating'
                            />
                            <span className='text-white mt-1'>7.6/10</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
