import React from 'react'

const JoinToday = () => {
    return (
        <div className='container mx-auto py-20'>
            <div className='border border-white rounded-2xl flex flex-col md:flex-row md:items-center justify-between'>
                <div className='flex items-center p-5 gap-3'>
                    <div className='bg-[#FFDD15] h-11 w-11 rounded-full flex justify-center items-center text-lg font-bold'>1</div>
                    <div className='flex flex-col'>
                        <p className='text-white font-bold'>Join Today</p>
                        <p className='text-[#CACACA]'>Deposit $10</p>
                    </div>
                </div>
                <div className='flex items-center md:justify-center p-5 gap-3'>
                    <div className='bg-[#FFDD15] h-11 w-11 rounded-full flex justify-center items-center text-lg font-bold'>2</div>
                    <div className='flex flex-col'>
                        <p className='text-white font-bold'>Play with $30</p>
                        <p className='text-[#CACACA]'>+3 Mystery Gifts</p>
                    </div>
                </div>
                <div className='flex items-center md:justify-center p-5 gap-3'>
                    <div className='bg-[#FFDD15] h-11 w-11 rounded-full flex justify-center items-center text-lg font-bold'>3</div>
                    <div className='flex flex-col'>
                        <p className='text-white font-bold'>Play Games</p>
                        <p className='text-[#CACACA]'>700+ Top Games</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default JoinToday