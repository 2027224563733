import React, { useEffect } from 'react';
import Title from '../Title';
import axiosInstance from '../../utils/AxiosInstance';
import { Link } from 'react-router-dom';
import { OurPlatformData } from '../../redux/slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const OurPlatforms = () => {
    const ourPlatform = useSelector((state) => state.auth.ourPlatform);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchPlatforms = async () => {
            try {
                const response = await axiosInstance.post('get_game_list.php', { "gameID": "" });
                if (response.data.status.code === "1") {
                    dispatch(OurPlatformData({ ourPlatform: response.data.data }));
                }
            } catch (error) {
                console.error('Error fetching platforms:', error);
            }
        };

        fetchPlatforms();
    }, [dispatch]);

    return (
        <div className='container mx-auto text-white mt-5'>
            <Title title={"OUR"} continueTitle={"PLATFORM"} />
            <div className='flex justify-center'>
                <div className='mt-10 grid grid-cols-2 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-7'>
                    {ourPlatform?.map((platform) => (
                        <Link
                            to={`/games/description/${platform.id}`}
                            key={platform.id}
                            className='w-[140px] h-[230px] xs:w-[200px] xs:h-[280px] flex flex-col justify-center items-center gap-3'
                        >
                            <div>
                                <img
                                    src={platform.game_image}
                                    alt={`${platform.game_name} img`}
                                    className='w-[180px] h-[180px] border border-[#FFDD15] rounded-2xl object-contain'
                                />
                            </div>
                            <div className='ourPlatformDropShadow border w-full border-[#FFDD15] px-3 rounded-full py-1 text-sm text-center font-bold  sm:line-clamp-1'>
                                {platform.game_name}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurPlatforms;
