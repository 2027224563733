import React from 'react'
import ReferralImg from '../../assets/image/referral.webp'
import { Link } from 'react-router-dom'

const ReferralProgram = () => {
    return (
        <div className="flex flex-col min-h-screen bg-[#131A2A] p-4 md:p-8 md:py-36">
            <div className="flex flex-col md:flex-row items-center md:space-x-8 bg-[#1F2937] p-6 sm:p-8 rounded-lg shadow-lg text-white">
                <div className="w-full md:w-1/2 flex justify-center mb-6 md:mb-0">
                    <img
                        src={ReferralImg}
                        alt="Bonus Wheel"
                        className="w-full max-w-xs xs:max-w-sm sm:max-w-md md:max-w-lg"
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <h2 className="text-xl xs:text-2xl sm:text-3xl font-bold mb-4">How to Claim this Bonus?</h2>
                    <p className="mb-4">
                        As you enter your BitPlay account you will find the profile tab on your account.
                        All you need to do is to click on that tab to copy the referral code and send it to your friends!
                    </p>
                    <p className="mb-4">
                        As your friends use the referral code when signing up at BitPlay, and deposit at least $10, both of you will be granted $10 worth of free credits. Depending on the number of new players that you invite to the platform, you might generate even more money which can be used to play a variety of exciting online sweepstakes games that we offer.
                    </p>
                    <p className="mb-6">
                        Don't miss out on this amazing opportunity and share your luck with your friends!
                    </p>
                    <Link
                        to="/user/referrals"
                        className="inline-block bg-purple-600 text-white py-2 px-4 rounded-full text-center hover:bg-purple-700"
                    >
                        Get Your Referral Code →
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ReferralProgram
