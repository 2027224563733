import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/AxiosInstance';
import BlogHead from './BlogHead';
import BlogMain from './BlogMain';
import { useParams } from 'react-router-dom';
import Loading from '../Common/Loading';
import RelatedBlog from './RelatedBlog';

const Details = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [blogsDetails, setBlogsDetails] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [loadings, setLoadings] = useState(true);

  useEffect(() => {

    const fetchData = async () => {
      await fetchCategories();
      await fetchBlogsDetails();
    };

    fetchData();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get('blog_categories_list.php');
      if (response.data && response.data.status.code === 1) {
        // Map the API response to the format you need
        const formattedCategories = response.data.data.map(cat => ({
          id: cat.id,
          name: cat.cat_name,
        }));
        setCategories(formattedCategories);
      } else {
        console.error('Error in response:', response.data.status.message);
        setCategories([]);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    } finally {
      setLoadings(false);
    }
  };

  const fetchBlogsDetails = async () => {
    const body = {
      id : id
    }
    try {
      const response = await axiosInstance.post('blog_details.php', body);
      if (response.data.status.code === 1) {
        setBlogsDetails(response.data.data?.blog_detail);
        setRelatedBlogs(response.data.data?.related_blog);
      } else {
        setBlogsDetails([]);
        setRelatedBlogs([]);
      }
    } catch (error) {
      setBlogsDetails([]);
      setRelatedBlogs([]);
    } finally {
      setLoadings(false);
    }
  };

  if (loadings) return <Loading />;

  return (
    <>
      <section className='mx-auto container mt-10 md:mt-20 mb-10 text-white relative block'>
        <BlogHead blogsDetails={blogsDetails} />
        <BlogMain categories={categories} blogsDetails={blogsDetails} />
        <RelatedBlog relatedBlogs={relatedBlogs} />
      </section>
    </>
  )
}

export default Details
