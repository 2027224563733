import { toast } from "react-toastify";
import { logout } from "../redux/slice/authSlice";
import { resetGamesState } from "../redux/slice/gamesSlice";

export const adminApi = async (url, method, body, header, dispatch, navigate) => {
  const api = 'https://api.luckycharmsweep.com/api/v2/dfjfngjn' + url;

  try {
    const headers = {
      'Content-Type': 'application/json',
    };

    // Add Authorization header only if the 'header' value is provided
    if (header) {
      headers.Authorization = `Bearer ${header}`;
    }

    const response = await fetch(api, {
      method: method,
      headers: headers,
      body: body !== undefined && body !== null ? JSON.stringify(body) : undefined,
    });

    const result = await response.json();

    if (!response?.ok) {
      if (result.status === 401) {
        toast.error(result.message)
        dispatch(logout());
        dispatch(resetGamesState());
        navigate('/')
      } else if (result.status === 404) {
        throw toast.error(result.message)
      } else if (response.status === 500) { // Handle 500 Internal Server Error
        navigate("/500");
        throw new Error("Internal Server Error");
      }
    }

    return result;
  } catch {
    navigate("/500")
    toast.error("Something went wrong. Please try again.");
  }
};
