import React from 'react'
import MW from '../../assets/image/luckcharmsweep.png';

const About = () => {
  return (
    <>
      <div className='container mx-auto my-10 px-4'>
        <div>
          <div className='flex flex-col md:flex-row gap-5'>
            <div className='w-full md:w-1/2 flex justify-center items-center'>
              <div className='px-5 py-10 bg-[#222222]'>
                <img src={MW} alt="milky-way-casino" className='w-full md:w-[300px] object-contain' />
              </div>
            </div>
            <div className='w-full md:w-1/2 flex flex-col gap-3'>
              <h1 className='uppercase text-white font-bold text-xl sm:text-2xl'>
                Lucky Charm Sweep - The Best Online Sweepstakes Platform In Los Angeles
              </h1>
              <p className='text-[#CACACA] text-sm sm:text-base'>
                Lucky Charm Sweep offers you the best slot games that are guaranteed to give you a memorable experience. We give you the latest and greatest online slots right to your doorstep to take advantage of the exceptional gaming experience whenever and wherever you want.
              </p>
              <p className='text-[#CACACA] text-sm sm:text-base'>
                If you’re looking for something more than an online sweepstakes platform, Lucky Charm Sweep is the right place. Every moment you spend on our platform helps you to learn more about the games and develop effective strategies that can lead you to massive jackpots. We’ve made the platform to make it easy and stress-free for you.
              </p>
              <p className='text-[#CACACA] text-sm sm:text-base'>
                At Lucky Charm Sweep, over 25 sweepstakes platforms are available to you all in one place! You can play quality slot games from various platforms like
              </p>
              <div className='mt-3'>
                <button className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base">
                  REGISTER
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#290A47] border-l-[3px] border-b-[3px] border-[#EC29FC] p-6 rounded-lg text-white mt-20">
          <div className="flex flex-col gap-2 mb-6 lg:mb-0 justify-center items-center mx-auto  max-w-[700px]">
            <h2 className="text-center text-2xl sm:text-4xl font-bold mb-4">
              Aside from all these, we're available 24/7
            </h2>
            <p className="text-center text-[#CACACA] text-sm sm:text-base">
              You can contact us anytime. We are here to serve you! All you need to do right now is take a couple of minutes to register, deposit cash or Bitcoin, and start playing your favorite slots!
            </p>
            <div className='mt-3'>
              <button className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base">
                REGISTER NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
