import React from 'react';
import MW from '../../assets/image/mw.png';
import MW2 from '../../assets/image/mw2.png';
import MW3 from '../../assets/image/mw3.png';

const MilkyWayCasino = () => {
    return (
        <div className='container mx-auto my-10 px-4'>
            <div>
                <div className='flex flex-col md:flex-row gap-5'>
                    <img src={MW} alt="milky-way-casino" className='w-full md:w-[200px] object-contain' />
                    <div className='flex flex-col gap-3'>
                        <h1 className='uppercase text-white font-bold text-xl sm:text-2xl'>milky way casino</h1>
                        <p className='text-[#CACACA] text-sm sm:text-base'>
                            Diamond Shot gives off a real estate vibe immediately away. If you enjoy slot
                            machines, you will enjoy this game. The game has fast-paced reels and a timer that
                            allows you to win a jackpot.
                        </p>
                        <p className='text-[#CACACA] text-sm sm:text-base'>
                            As you spin the reels, they will fly by. Furthermore, the matching pre-bonus adds to the game's volatility. It's a unique take on free gaming bonuses that will pique your curiosity.
                        </p>
                        <p className='text-[#CACACA] text-sm sm:text-base'>
                            The Diamond Shot will appeal to you, from the brilliant blue geometric background to the symbols. There are a lot of symbols, and many of them look identical, so you could assume there aren't as many as you think.
                        </p>
                        <p className='text-[#CACACA] text-sm sm:text-base'>
                            Diamond Shot is nostalgic, but it isn't dull. Instead, it's a fantastic slot game with superb music and visual effects.
                        </p>
                        <div className='mt-3'>
                            <button className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base">
                                DEPOSIT NOW
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#290A47] border-l-[3px] border-b-[3px] border-[#EC29FC] p-6 rounded-lg flex flex-col lg:flex-row justify-between items-center text-white mt-20">
                <div className="flex flex-col gap-2 mb-6 lg:mb-0">
                    <h2 className="text-xl sm:text-2xl font-bold mb-4">MILKY WAY CASINO</h2>
                    <p className="text-[#CACACA] text-sm sm:text-base">
                        Diamond Shot gives off a real estate vibe immediately away. If you enjoy slot machines, you will enjoy this game. The game has fast-paced reels and a timer that allows you to win a jackpot.
                    </p>
                    <p className="text-[#CACACA] text-sm sm:text-base">
                        As you spin the reels, they will fly by. Furthermore, the matching pre-bonus adds to the game's volatility. It's a unique take on free gaming bonuses that will pique your curiosity.
                    </p>
                    <p className="text-[#CACACA] text-sm sm:text-base">
                        The Diamond Shot will appeal to you, from the brilliant blue geometric background to the symbols. There are a lot of symbols, and many of them look identical, so you could assume there aren't as many as you think.
                    </p>
                    <p className="text-[#CACACA] text-sm sm:text-base">
                        Diamond Shot is nostalgic, but it isn't dull. Instead, it's a fantastic slot game with superb music and visual effects.
                    </p>
                    <div className='mt-3'>
                        <button className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base">
                            DEPOSIT NOW
                        </button>
                    </div>
                </div>
                <div className="w-full lg:w-auto lg:ml-6">
                    <img
                        src={MW2}
                        alt="Game Preview"
                        className="rounded-lg w-full lg:w-auto"
                    />
                </div>
            </div>
            <div className="bg-[#290A47] border-r-[3px] border-b-[3px] border-[#EC29FC] p-6 rounded-lg flex flex-col lg:flex-row justify-between items-center text-white mt-20 gap-5">
                <div className="w-full lg:w-auto lg:ml-6">
                    <img
                        src={MW3}
                        alt="Game Preview"
                        className="rounded-lg w-full lg:w-auto"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <h2 className="text-xl sm:text-2xl font-bold mb-4">
                        PLAY FANTASTIC MILKY WAY GAMES WITH HD GRAPHICS
                    </h2>
                    <p className="text-[#CACACA] text-sm sm:text-base">
                        Diamond Shot gives off a real estate vibe immediately away. If you enjoy slot machines, you will enjoy this game. The game has fast-paced reels and a timer that allows you to win a jackpot.
                    </p>
                    <p className="text-[#CACACA] text-sm sm:text-base">
                        As you spin the reels, they will fly by. Furthermore, the matching pre-bonus adds to the game's volatility. It's a unique take on free gaming bonuses that will pique your curiosity.
                    </p>
                    <p className="text-[#CACACA] text-sm sm:text-base">
                        The Diamond Shot will appeal to you, from the brilliant blue geometric background to the symbols. There are a lot of symbols, and many of them look identical, so you could assume there aren't as many as you think.
                    </p>
                    <p className="text-[#CACACA] text-sm sm:text-base">
                        Diamond Shot is nostalgic, but it isn't dull. Instead, it's a fantastic slot game with superb music and visual effects.
                    </p>
                    <div className="mt-4">
                        <button className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base">
                            DEPOSIT NOW
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MilkyWayCasino;
