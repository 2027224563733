import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BitCoinLogo from '../../../assets/image/bitcoin.png';
import cashAppLogo from '../../../assets/image/cashApp.png';
import DogeCoinLogo from '../../../assets/image/dogecoin.png';
import LitecoinLogo from '../../../assets/image/Litecoin.png';
import PaymentButton from '../../../components/PaymentButton';
import { formatMoment, onBlurDate, onFocusDate, submitDATE, tableDATE } from '../../../helper/CommonFunction';
import DataFetching from '../../../helper/DataFetching';
import renderDataTable from '../../../helper/renderDataTable';
import axiosInstance from '../../../utils/AxiosInstance';
import { InfoIcon } from '../../../utils/Icons';
import { toast } from 'react-toastify';
import Loading from '../../../components/Common/Loading';
import { UPDATE_BALANCE } from '../../../redux/slice/authSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [walletData, setWalletData] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const [errors, setErrors] = useState({});
  const [promocodeDiscount, setPromocodeDiscount] = useState('');
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [totalamount, setTotalamount] = useState(0);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [disabledTrue, setDisabledTrue] = useState(true);

  const userDetails = useSelector((state) => state.auth.user);
  const userData = useSelector((state) => state.auth.user);
  const email = userDetails?.email;

  const handlePromoApply = async () => {
    const response = await axiosInstance.post('/verify_promocode.php', {
      promocode: promoCode,
    });

    if (response?.data?.status?.code === 1) {
      toast.success("Coupon code applied successfully! Enjoy your rewards.");
      const res = response.data.data;
      const discount = amount * (parseFloat(res.promocode_percentage) / 100); // Calculate discount amount

      setPromocodeDiscount(res.promocode_percentage);
      setDiscountedAmount(discount);
      setTotalamount(amount - discount);
      setIsPromoApplied(true);
    } else {
      toast.error(response?.data?.status?.message);
      setIsPromoApplied(false);
      setPromocodeDiscount('');
      setDiscountedAmount(0);
      setTotalamount(amount);
    }
  };

  const handleAmountClick = (value) => {
    setAmount(value);
    setTotalamount(value);
    if (selectedMethod) {
      setDisabledTrue(false);
    }
  };

  const handleClick = (method) => {
    setSelectedMethod(method);
    if (amount) {
      setDisabledTrue(false);
    }
  };



  const handleTopUpSubmit = async () => {
    const body = {
      amount: totalamount ? parseFloat(totalamount) : '',
      email: email,
      promocode: promoCode,
      promocodeDiscount: promocodeDiscount ? parseFloat(promocodeDiscount) : '',
      discountamount: isPromoApplied ? discountedAmount ? parseFloat(discountedAmount) : '' : '',
      totalamount: amount ? parseFloat(amount) : '',
      payment_type: selectedMethod,
      cashAppScreenshot: '',
    }

    if (selectedMethod === 'CashApp') {
      try {
        setLoading(true);
        const response = await axiosInstance.post("get_cashApp_barcode_image.php", {});
        const barcodeImage = response.data?.data?.barcode_image;

        if (response.data.status.code === 1 && barcodeImage) {
          navigate("/cashapp", {
            state: {
              directPassPage: true,
              isCheckoutAllowed: true,
              totalAmountState: parseFloat(totalamount),
              method: selectedMethod,
              promocodeDiscount: promocodeDiscount,
              discountamount: isPromoApplied ? discountedAmount : '',
              finalTotal: parseFloat(amount),
              promoCode: promoCode,
              userData: userData,
              proof_image: barcodeImage,
              typeApi: true,
              totalAmount: parseFloat(amount)
            },
          });
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching CashApp barcode:", error);
        setLoading(false);
      } 
    } else {
      try {
        setLoading(true);
        const response = await axiosInstance.post('/get_wallet_recharge_detail.php', body);
        if (response?.data?.status === 401) {
          toast.error(response?.data?.message);
        } else {
          setLoading(false);
          window.location.href = response?.data?.data?.checkoutLink;
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      } 
    }
  }

  const columns = [
    {
      name: 'Date Created',
      selector: (row) => formatMoment(row.created_date),
      sortable: true,
    },
    {
      name: 'Total Price',
      selector: (row) => row.user_balance,
      sortable: true,
    },
    {
      name: 'Status',
      // Pending, Expired, Cancelled, Completed, Paid, Unpaid
      selector: row => row.payment_status === "0" ? (
        <p className='text-[#F8924F]'>Pending</p>
      ) : row.payment_status === "3" ? (
        <p className='text-[#FF2B62]'>Expired</p>
      ) : row.payment_status === "2" ? (
        <p className='text-[#FF2B62]'>Cancelled</p>
      ) : row.payment_status === "1" ? (
        <p className='text-[#4AFFA9]'>Completed</p>
      ) : row.payment_status === "Paid" ? (
        <p className='text-[#9EE2FF]'>Paid</p>
      ) : row.payment_status === "Unpaid" ? (
        <p className='text-[#8F96FF]'>Unpaid</p>
      ) : (
        <></>
      ),
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => <Link to={`/user/wallet/${row.id}/${row.order_id}`} className='underline text-[#01D370] bg-[#0E0E0E]'>View</Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [user, setUser] = useState({
    totalPrice: '',
    status: '',
  })
  const { totalPrice, status } = user;

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const token = useSelector(state => state.auth.token);

  const location = useLocation();
  const [pending, setPending] = useState(true);
  const [resetSearch, setResetSearch] = useState(true);
  const [hide, setHide] = useState('');
  const [from, setFrom] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [form_date, setForm_date] = useState(null);
  const [end_date, setEnd_date] = useState(null);
  const [form_date1, setForm_date1] = useState('');
  const [end_date1, setEnd_date1] = useState('');

  const fetchData = async () => {
    setPending(true)

    const body = {
      fromDate: form_date1,
      toDate: end_date1,
      total_price: totalPrice,
      payment_status: status,
      page: currentPage - 1,
      total_record: pageLimit
    }
    DataFetching(setPending, '/wallet_transaction_history.php', 'POST', token, dispatch, navigate, setFilteredData, setTotalPages, body, setResetSearch, resetSearch);
  };

  useEffect(() => {
    getGeneralWalletData();
  }, []);

  const getGeneralWalletData = async () => {
    try {
      const response = await axiosInstance.get('/user_balance.php');
      // Dispatch the action to update the balance
      const { main_balance, bonus_balance, total_deposit } = response.data.data;
      dispatch(UPDATE_BALANCE({ main_balance, bonus_balance, total_deposit }));
      setWalletData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearchClick = () => {
    if (form_date) {
      if (!end_date) {
        setHide("This field is required")
        return;
      }
    }
    if (hide || from) {
      return;
    }
    setCurrentPage(1)
    setResetSearch((prevReset) => !prevReset);
  };

  const handleClearSearch = () => {
    setHide('')
    setFrom(false);
    clearSearchData();
    setResetSearch((prevReset) => !prevReset);
  }
  const clearSearchData = () => {
    setUser({
      totalPrice: '',
      status: '',
    })
    setForm_date(null);
    setForm_date1('');
    setEnd_date(null);
    setEnd_date1('');
  };

  // Render the DataTable component
  useEffect(() => {
    if (!resetSearch) {
      return;
    }
    fetchData();
  }, [currentPage, pageLimit, location, resetSearch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen text-white p-4 sm:p-8">
      <h1 className="text-center text-3xl md:text-5xl">Dashboard</h1>
      <h2 className="text-2xl md:text-3xl mt-4">Balance</h2>
      <div className="main-dot-bg flex flex-col sm:flex-row justify-between p-5 mt-4">
        <div className="mb-4 sm:mb-0">
          <p className="text-2xl md:text-4xl">${+walletData?.main_balance}</p>
          <p className="text-md md:text-xl tracking-wide text-[#FFDD15] flex items-center gap-2">
            Main Balance
            <InfoIcon className="cursor-pointer" />
          </p>
        </div>
        <div>
          <p className="text-2xl md:text-4xl">${+walletData?.bonus_balance}</p>
          <p className="text-md md:text-xl tracking-wide text-[#FFDD15] flex items-center gap-2">
            Bonus Balance
            <InfoIcon className="cursor-pointer" />
          </p>
        </div>
      </div>

      {/* Top-up Section */}
      <div className="main-dot-bg p-5 mt-10">
        <h2 className="text-2xl md:text-4xl">TOP UP</h2>
        <div className="flex flex-col lg:flex-row justify-center my-5 gap-5 w-full">
          {[10, 20, 50, 100].map((value) => (
            <button
              key={value}
              className={`md:w-full w-auto py-1 text-xl ${amount === value ? 'bg-[#FFDD15] text-[#0E0E0E]' : 'border border-[#FFDD15] bg-transparent'} rounded`}
              disabled={isPromoApplied}
              onClick={() => handleAmountClick(value)}
            >
              {value}$
            </button>
          ))}
          <input
            type="number"
            placeholder="Enter amount"
            className="px-4 py-2 border border-[#FFDD15] bg-[#222222] text-white outline-none rounded w-full sm:w-auto placeholder:text-white"
            disabled={isPromoApplied}
            onChange={(e) => { setAmount(Number(e.target.value)); setTotalamount(Number(e.target.value)) }}
          />
        </div>

        {!isPromoApplied && (
          <p className="text-xl font-bold text-[#FFDD15]">Total amount:
            <span className='text-[#01D370] font-bold pl-1'>${amount}</span>
          </p>
        )}

        {/* Promo Code Section */}
        <div className="mt-5">
          <p className="mb-2 font-semibold text-white text-lg">Promo code</p>
          <div className='flex flex-col sm:flex-row items-start justify-start gap-3'>
            <div>
              <input
                type="text"
                placeholder="ENTER PROMO CODE"
                className="px-4 py-2 bg-[#0E0E0E] rounded  w-auto  capitalize"
                style={{ borderWidth: '1px', borderColor: 'rgba(255, 255, 255, 0.16)' }}
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </div>
            <button
              className={`py-2 px-4 bg-[#290A47] text-white rounded-md font-semibold text-base ${!promoCode || isPromoApplied || !amount ? "cursor-not-allowed" : ""}`}
              style={{ borderWidth: '1px', borderColor: 'rgba(255, 255, 255, 0.16)' }}
              onClick={handlePromoApply}
              disabled={!promoCode || isPromoApplied || !amount}
            >
              Apply Promo Code
            </button>
          </div>

          {isPromoApplied && (
            <div className='flex flex-col items-start gap-1 font-semibold text-base mt-3'>
              <p className="text-[#01D370] font-bold">
                Promo code applied successfully!
              </p>
              <p className="font-semibold text-white text-lg">
                Amount: ${amount}
              </p>
              <p>
                Promocode total discount {promocodeDiscount}%
              </p>
              <p>
                Promocode total discount amount: ${discountedAmount}
              </p>
            </div>
          )}

          {isPromoApplied && (
            <p className="text-xl font-bold text-[#FFDD15] mt-4">Final amount:
              <span className='text-[#01D370] font-bold pl-1'>${totalamount}</span>
            </p>
          )}

        </div>

        <h2 className="text-2xl md:text-3xl mt-10">CHOOSE PAYMENT METHOD</h2>
        <div className="flex flex-col sm:flex-row justify-start gap-4 mt-5">
          <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 w-full md:w-full lg:w-2/3">
            <PaymentButton
              bgColor={selectedMethod === 'BTC' ? "#D47D18" : "#F7931A"}
              logo={BitCoinLogo}
              title={"Bitcoin"}
              onClick={() => handleClick('BTC')}
            />
            <PaymentButton
              bgColor={selectedMethod === 'DOGE' ? "#9F8D2E" : "#C2A633"}
              logo={DogeCoinLogo}
              title={"Dogecoin"}
              onClick={() => handleClick('DOGE')}
            />
            <PaymentButton
              bgColor={selectedMethod === 'LTC' ? "#2D4F89" : "#345D9D"}
              logo={LitecoinLogo}
              title={"Litecoin"}
              onClick={() => handleClick('LTC')}
            />
            <PaymentButton
              bgColor={selectedMethod === 'CashApp' ? "#E6716D" : "#FF9085"}
              logo={cashAppLogo}
              title={"CashApp"}
              onClick={() => handleClick('CashApp')}
            />
          </div>
        </div>

        <button
          className={`w-full mt-10  py-3 text-2xl rounded transition border border-[#FFDD15] ${!amount || disabledTrue ? 'hover:bg-gray-400 hover:text-gray-700 cursor-not-allowed font-bold' : "hover:bg-[#FFDD15] hover:text-[#0E0E0E] bg-[#0E0E0E]"}`}
          onClick={() => {
            handleTopUpSubmit();
          }}
          disabled={!amount || disabledTrue}
        >
          TOP UP
        </button>
      </div>

      <div className="p-4 main-dot-bg text-white shadow-lg min-h-screen mt-10">
        <h2 className="text-2xl mb-4">Transactions</h2>

        <div className='p-5 mb-4 rounded-lg'>
          <p className='text-xl mb-2'>Filter:</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
            <div className='w-full'>
              <div className='text-white text-sm font-medium mb-2'>By From Transaction Date</div>
              <DatePicker
                selected={form_date}
                placeholderText="From Date"
                className={`px-4 h-[40px] text-start bg-[#0E0E0E] text-white rounded fill-white outline-none focus:outline-none w-full ${from ? "border border-red-500 placeholder:text-red-400 font-extrabold" : ""}`}
                onChange={(date) => tableDATE(date, setForm_date, setForm_date1, end_date, setFrom)}
                dateFormat="yyyy-MM-dd"
                onFocus={() => onFocusDate(setHide)}
                onBlur={() => onBlurDate(setHide, form_date, end_date)}
              />
            </div>
            <div className='w-full'>
              <div className='text-white text-sm font-medium mb-2'>By To Transaction Date</div>
              <DatePicker
                selected={end_date}
                onChange={(date) => {
                  submitDATE(
                    date, setHide, setEnd_date, setEnd_date1, setFrom, form_date
                  )
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText="To Date"
                className={`px-4 h-[40px] text-start bg-[#0E0E0E] text-white rounded fill-white outline-none focus:outline-none w-full ${hide ? "border border-red-500 placeholder:text-red-400 font-extrabold" : ""}`}
                minDate={form_date}
              />
              {hide && <div style={{ color: 'red', display: 'block', fontWeight: '500' }}>{hide}</div>}
            </div>
            <div className='w-full'>
              <p className='text-white text-sm font-medium mb-2'>Total Price</p>
              <input
                type="text"
                name="totalPrice"
                placeholder="Search by Total Price"
                value={totalPrice}
                onChange={(e) => onInputChange(e)}
                className="px-4 h-[40px] text-start bg-[#0E0E0E] text-white rounded outline-none focus:outline-none placeholder:text-white w-full"
              />
            </div>
            <div className='w-full'>
              <p className='text-white text-sm font-medium mb-2'>Transaction Status </p>
              <select
                name="status"
                value={status}
                onChange={(e) => onInputChange(e)}
                className="px-4 h-[40px] text-start bg-[#0E0E0E] text-white rounded outline-none focus:outline-none placeholder:text-white w-full"
              >
                <option value="">Search by Status</option>
                <option value="pending">Pending</option>
                <option value="expired">Expired</option>
                <option value="cancelled">Cancelled</option>
                <option value="completed">Completed</option>
                <option value="paid">Paid</option>
                <option value="unpaid">Unpaid</option>
              </select>
            </div>
          </div>

          <div className='flex gap-3 justify-end mt-4'>
            <button
              onClick={handleSearchClick}
              className="mb-4 px-4 py-2 bg-[#5C6CB2] rounded text-white hover:bg-blue-700"
            >
              Search
            </button>
            <button
              onClick={handleClearSearch}
              className="mb-4 px-4 py-2 bg-[#6C6C6C] rounded text-white hover:bg-slate-600"
            >
              Clear
            </button>
          </div>
        </div>

        {renderDataTable(
          filteredData,
          columns,
          pending,
          currentPage,
          setPageLimit,
          setCurrentPage,
          totalPages,
          pageLimit,
          setResetSearch,
        )}
      </div>

    </div>
  );
};

export default Dashboard;
