import React, { useEffect, useState } from 'react';
import RequestImg from '../../../assets/image/requestGame.png';
import axiosInstance from '../../../utils/AxiosInstance';
import Loading from '../../../components/Common/Loading';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RequestRedeem = () => {
    const [amount, setAmount] = useState('');
    const [username, setUsername] = useState('');
    const [withdrawalMethod, setWithdrawalMethod] = useState('');
    const [cryptoUserId, setCryptoUserId] = useState('');  // For Bitcoin, Dogecoin, Litecoin
    const [cashAppFile, setCashAppFile] = useState(null);  // For CashApp file upload
    const [error, setError] = useState('');
    const [selectedGame, setSelectedGame] = useState(null);
    const [updateGame, setUpdateGame] = useState([]);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [fetchError, setFetchError] = useState('');
    const { activeLevel, token, user } = useSelector(state => state.auth);
    const navigate = useNavigate();

    // Handle file selection
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            // Validate file type and size
            const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg']; // Allowed file types

            if (!allowedFileTypes.includes(file.type)) {
                setError('Only PNG, JPG, and JPEG formats are allowed.');
                return;
            }

            // Now call the API to upload the file
            const formData = new FormData();
            formData.append('cashAppScreenshot', file); // Append file to the form data


            try {
                const response = await axiosInstance.post(
                    '/getCashAppImgForRedeem.php',
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.data.status.code === 1) {
                    setCashAppFile(response.data.data)
                } else {
                    setError('Failed to upload screenshot. Please try again.');
                    toast.success(response.data.status.message);
                }
            } catch (error) {
                setError('Error uploading screenshot. Please try again.');
                toast.error('Error uploading screenshot. Please try again.');
            }


        }
    };

    const handleGameChange = (event) => {
        const selectedGameId = event.target.value;
        const selectedGame = updateGame.find(game => game.id === selectedGameId);
        setSelectedGame(selectedGame);
        setDisabled(false)

        // Auto-fill the username from the selected game and make it read-only
        if (selectedGame) {
            setUsername(selectedGame.username);
        }
    };

    const fetchPlatforms = async () => {
        try {
            const response = await axiosInstance.post('/get_game_request_id_pass.php', { gameID: "" });
            if (response.data.status.code === 1) {
                const data = response.data.data;
                setUpdateGame(data);
            } else {
                setUpdateGame([]);
                setFetchError('Failed to fetch game data');
            }
        } catch (error) {
            console.error('Error fetching game data:', error);
            setUpdateGame([]);
            setFetchError('Error fetching game data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPlatforms();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        if (
            parseFloat(amount) < 10 || 
            parseFloat(amount) > (activeLevel?.withdrawal_limit ? parseFloat(activeLevel?.withdrawal_limit) : 100)
          ) {
            setError(`Amount must be between $20 and $${activeLevel?.withdrawal_limit}.`);
            return;
          }

        if (['Bitcoin', 'Dogecoin', 'Litecoin'].includes(withdrawalMethod) && !cryptoUserId) {
            setError("Crypto User ID is required.");
            return;
        }

        if (withdrawalMethod === 'CashApp' && !cashAppFile) {
            setError("Please upload a file for CashApp.");
            return;
        }

        const body = {
            game_id: selectedGame?.game_id,
            email: user.email,
            phone: user.phone,
            game_name: selectedGame?.game_name,
            game_username: username,
            amount: amount,
            withdrawal_method: withdrawalMethod,
            crypto_user_id: withdrawalMethod === 'CashApp' ? cashAppFile : cryptoUserId,
        }

        try {
            const response = await axiosInstance.post('/redeem_request_for_game.php', body);

            if (response.data.status.code === 1) {
                toast.success("Your request has been successfully submitted and will be processed within an hour.");
                setWithdrawalMethod('')
                setCryptoUserId('')
                setCashAppFile(null);
                setSelectedGame(null);
                setAmount('');
                setUsername('');
                navigate('/user/withdrawals')
            } else {
                setWithdrawalMethod('')
                setCryptoUserId('')
                setCashAppFile(null);
                setSelectedGame(null);
                setAmount('');
                setUsername('');
                toast.error(response.data.status.message);
            }
        } catch (error) {
            setWithdrawalMethod('')
            setCryptoUserId('')
            setCashAppFile(null);
            setSelectedGame(null);
            setUpdateGame([]);
            setAmount('');
            setUsername('');
            toast.error('Error submitting redeem request. Please try again.');
            setError('Error submitting redeem request. Please try again.');
        }
    };

    // Check if there are valid games with non-empty username and password
    const validGames = updateGame.filter(game => game.username !== '' && game.password !== '');

    if (loading) return <Loading />;

    return (
        <div className="flex justify-center min-h-screen bg-[#0e0e0e]">
            <div className="bg-[#222222] text-white p-8 rounded-lg shadow-md w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl xxl:max-w-2xl">
                <h2 className="text-2xl font-bold text-center text-[#FFDD15] underline mb-4">Request Redeem</h2>

                <div className="mb-6 flex justify-center">
                    <img
                        src={RequestImg}
                        alt="Deposit Illustration"
                        className="w-44 rounded-lg"
                    />
                </div>

                {/* Game Selection */}
                <div className="mb-4">
                    <label className="block text-white mb-2">Select a Game</label>
                    <select
                        className="w-full px-2 py-3 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                        onChange={handleGameChange}
                        defaultValue=""
                    >
                        <option value="" disabled>
                            {updateGame.length > 0 && validGames.length > 0 ? "Select a Game" : "No games available"}
                        </option>
                        {validGames.length > 0 && validGames.map(game => (
                            <option key={game.id} value={game.id}>
                                {game.game_name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Display Selected Game Details */}
                {selectedGame && (
                    <div className="overflow-x-auto mb-4">
                        <table className="w-full text-left text-white border border-gray-700 bg-black">
                            <thead>
                                <tr className="bg-black">
                                    <th className="px-4 py-2 text-white/50 font-normal">Logo</th>
                                    <th className="px-4 py-2 text-white/50 font-normal">Name</th>
                                    <th className="px-4 py-2 text-white/50 font-normal">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="px-4 py-2">
                                        <img src={selectedGame.game_image} alt={selectedGame.game_name} className="w-20 h-20 rounded-lg" />
                                    </td>
                                    <td className="px-4 py-2">{selectedGame.game_name}</td>
                                    <td className="px-4 py-2 text-[#01D370]">${selectedGame.game_price}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Input fields */}
                <div className="mb-4">
                    <label className="block text-white mb-2">Game Username</label>
                    <input
                        type="text"
                        value={username}
                        readOnly
                        placeholder="Game username"
                        className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-white mb-2">Amount ($)</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter an amount"
                        className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                    />
                </div>

                {/* Withdrawal Method Selection */}
                <div className="mb-4">
                    <label className="block text-white mb-2">Withdrawal Method</label>
                    <select
                        value={withdrawalMethod}
                        onChange={(e) => setWithdrawalMethod(e.target.value)}
                        className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                    >
                        <option value="">Select a method</option>
                        <option value="Bitcoin">Bitcoin</option>
                        <option value="Dogecoin">Dogecoin</option>
                        <option value="Litecoin">Litecoin</option>
                        <option value="CashApp">CashApp</option>
                        <option value="Wallet">Wallet</option>
                    </select>
                </div>

                {/* Conditionally show input for crypto user ID */}
                {['Bitcoin', 'Dogecoin', 'Litecoin'].includes(withdrawalMethod) && (
                    <div className="mb-4">
                        <label className="block text-white mb-2">Crypto User ID</label>
                        <input
                            type="text"
                            value={cryptoUserId}
                            onChange={(e) => setCryptoUserId(e.target.value)}
                            placeholder="Enter your crypto user ID"
                            className="w-full py-3 px-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                        />
                    </div>
                )}

                {/* Conditionally show file input for CashApp */}
                {withdrawalMethod === 'CashApp' && (
                    <div className="mb-4">
                        <label className="block text-white mb-2">Upload CashApp Screenshot</label>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                        />
                    </div>
                )}

                {/* Error Display */}
                {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
                {fetchError && <div className="text-red-500 text-sm mb-4">{fetchError}</div>}

                {/* Submit Button */}
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className={`w-full py-2 rounded-md font-bold text-lg mt-7
                         ${!amount || !withdrawalMethod || !username || disabled
                            ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                            : "bg-[#FFDD15] text-black"
                        }`}
                    disabled={!amount || !withdrawalMethod || !username || disabled}
                >
                    Submit Request
                </button>
            </div>
        </div>
    );
}

export default RequestRedeem;
