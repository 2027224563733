import { useState } from 'react';
import FAQItem from './FaqItem'
import FAQUl from './FaqUl'
import MultipleFaq from './MultipleFaq';

const FaqContent = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="relative overflow-hidden py-20 container mx-auto">
      <div className="about-divider-gradient absolute left-1/2 top-0 h-[1px] w-full max-w-[1170px] -translate-x-1/2"></div>
      <div className="px-6">
        <div className="wow fadeInUp space-y-6">
          <FAQItem
            index={0}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What is Lucky Charm Sweep?`}
            answer={`Lucky Charm Sweep is an online sweepstakes platform that provides the latest online slot games and slots at the comfort of your home. We give you zero financial risks and 100% privacy due to the crypto solutions we offer. There are more than 500 unique games for you to enjoy with your friends and colleagues. You’re guaranteed to have the best time ever!`}
          />
          <FAQUl
            index={1}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How many platforms are available on Lucky Charm Sweep?`}
            answer={`As of right now, there are 26 platforms that you can check out in Lucky Charm Sweep. The list includes `}
            liData={[
              `Vegas Sweeps`,
              `E Game`,
              `Funzone`,
              `Ultra Panda`,
              `Lucky Dragon`,
              `ICE8`,
              `Game Vault`,
              `V blink`,
              `Fire Master`,
              `TAI CHI Master`,
              `Paradise Casino`,
              `Red Play`,
              `Blue Dragon`,
              `Gold Hunter`,
              `Fire Kirin`,
              `Juwa`,
              `Golden Dragon`,
              `Panda Master`,
              `Orion Stars`,
              `Golden Treasure`,
              `Riversweeps fun`,
              `Flamingo7`,
              `ICE7`,
              `Admiral`,
              `Milky Way`,
              `Vegas Volts`,
            ]}
          />
          <FAQItem
            index={2}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do you register on Lucky Charm Sweep?`}
            answer={`To register on Lucky Charm Sweep, Click on the “Sign up" button on the Lucky Charm Sweep official website. You'll be redirected to the registration pages, where you have to fill in all the necessary details. After the registration, your account will be pending while we approve your request. When your account gets approved, you’ll get a confirmation email with all the necessary details. You can also request to play on other platforms by clicking on your profile's “Platforms” tab.`}
          />
          <FAQItem
            index={3}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What is an ID number in registration?`}
            answer={`The identity number is printed on all of your national identification documents, such as your ID card, passport, residence permit, etc.`}
          />
          <FAQItem
            index={4}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I deposit on Lucky Charm Sweep?`}
            answer={`In order to deposit, click on Deposit on the navigation bar. Deposit the amount you want to add and add it to the cart. Proceed to the checkout and select your preferred platform. Review the order and pay with either Bitcoin, Litecoin or Dogecoin. You can scan the QR code with your Bitcoin, Litecoin or Dogecoin wallet in the payment section or just send the amount to the shown BTC/DOGE address.`}
          />
          <FAQItem
            index={5}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What is a weekly bonus, and how is it calculated?`}
            answer={`The weekly bonus is a chance for you to get a bonus every day of the week. But you can only use it once a day. Daily bonuses and User Role bonuses are included in your balance with the weekly bonuses, except coupon codes.`}
          />
          <FAQItem
            index={6}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How long does it take for the deposit to be credited to my game account?`}
            answer={`The speed of the Bitcoin transfers is mostly dependent on the transaction speed/fee the sender chooses. If the sender is selecting to do the instant transaction or the transaction with the higher fee, it usually takes a short time to be completed. In general, it takes 0-3 hours for most of the transactions to be completed. We are processing the transactions within 30 minutes after we receive them. Whenever we receive the payment, you will receive an email that we have started to process your order. Please be informed that it is a normal procedure, and we ask for your patience during this process.`}
          />
          <FAQItem
            index={7}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I withdraw (redeem) from Lucky Charm Sweep?`}
            answer={`In order to withdraw from Lucky Charm Sweep, sign in to your account and click on “Withdrawal.” On that page, you’ll have to fill out a form that specifies the platform and the amount you want to withdraw. Usually, players use Bitcoin for withdrawal; however, due to the high volume of transactions on the blockchain, we are unable to cash out winnings to players with Bitcoin. So, instead, players can use Litecoin or Dogecoin to withdraw funds. Make sure that you have provided your cryptocurrency wallet address by putting it on your profile before you complete other steps.`}
          />

          <FAQItem
            index={8}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How long does it take to complete the withdrawal process?`}
            answer={`The process can take up to 8 hours because it includes the check on account, balance, and sending the amount in Bitcoin, which is sometimes delayed due to the traffic on the crypto network. But do not worry, in most cases, it takes way shorter than this!`}
          />

          <FAQUl
            index={9}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What are the withdrawal limitations?`}
            answer={``}
            liData={[
              `The users who did not make any load: Maximum $100 per day.`,
              `The users who have a total load of less than $4000: Maximum $200 per day`,
              `The users who have a total load of more than $4000: Maximum $300 per day.`,
              `The users who have a total load of more than $8000: Maximum $400 per day.`,
              `The users who have a total load of more than $16000: Maximum $500 per day.`,
              `The users who have a total load of more than $32000: Maximum $600 per day.`,
              `The users who have a total load of more than $64000: Maximum $700 per day.`,
              `The users who have a total load of more than $128000: Maximum $1000 per day.`,
              `The users who have a total load of more than $256000: Maximum $1500 per day.`,
              `The users who have a total load of more than $500000: Maximum $2000 per day.`,
              `The users who have a total load of more than $1000000: Unlimited`,
            ]}
          />

          {/* Baki */}
          <MultipleFaq
            index={10}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What are the withdrawal policies?`}
            answer={``}
          />
          {/*  */}

          <FAQItem
            index={11}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What happens if a player cheats?`}
            answer={`If a user is found to be committing fraud and has made a deposit, they’ll be suspended from the site for one week. If the person hasn’t made any deposits, the suspension will continue for three months. We caution any player to stay away from fraudulent acts.`}
          />

          <FAQItem
            index={12}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What will happen if I don’t enter my gaming account for a certain period?`}
            answer={`In that case, your account will be deactivated by Lucky Charm Sweep if the inactivity lasts longer than 60 days.`}
          />

          <FAQItem
            index={13}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What are the details of the requirement?`}
            answer={`For account security reasons, we may ask you to upload a selfie along with a piece of paper with the date you took the picture and the name of the platform.`}
          />

          <FAQItem
            index={14}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Why is this selfie necessary?`}
            answer={`It's one of the many security measures we use to verify that we're talking to the actual account holder and not an imposter. We know how inconvenient this is. But it's essential to provide you with the best possible account security. That's why we require such information.`}
          />

          <FAQItem
            index={15}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What is the best way to take this selfie without rejection?`}
            answer={`First, you, the Lucky Charm Sweep account holder, must take the selfie with a piece of paper that includes the date you took the picture. Any other person will be rejected. Second, the platform’s name and date should be handwritten to avoid computer-edited requests.`}
          />

          <FAQItem
            index={16}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How should I send the file?`}
            answer={`You can send the file via email. However, we strongly advise you to check the email you received to send the images.`}
          />

          <FAQItem
            index={17}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Can I withdraw my sign up bonus?`}
            answer={`In order to be able to make a withdrawal request you need to play all the bonus or make a deposit and win. The balance cannot be withdrawn after it is added without playing.`}
          />

          <FAQItem
            index={18}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What is a BTC address?`}
            answer={`A BTC wallet address is similar to a bank account number. It's a unique 26-35 digit combination of letters and numbers, and it looks something like this: 1ExAmpLe0FaBiTco1NADr3sSV5tsGaMF6hd.`}
          />

          <FAQItem
            index={19}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How to play Lucky Charm Sweep games?`}
            answer={`In order to play games, first, you need to register on the website. After registering, when we approve your account, you’ll get a confirmation email with all the necessary details. You can also request to play on other platforms by clicking on your profile's “Platforms” tab.`}
          />

          <FAQItem
            index={20}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How can I refer to my friend?`}
            answer={`As you enter your Lucky Charm Sweep account, you will find the profile tab on your account. All you need to do is to click on that tab to copy the referral code and send it to your friends. As your friends use the referral code when signing up at Lucky Charm Sweep and deposit at least $10, both of you will be granted $10 worth of free credits.`}
          />

          <FAQItem
            index={21}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive Bitcoin using Cash App?`}
            answer={`You have to download the Cash App. In the app, tap on the rising curve icon. Two available options, Bitcoin and Buy Stocks will be shown. Select Bitcoin, tap the buy button, and specify the amount you want to purchase. Click next. A confirmation screen will appear where you’ll see full details of the transaction. Complete the transaction by clicking on the confirm button.`}
            answer2={`To receive Bitcoin, go to the Banking tab on the main screen, select Bitcoin, and tap the deposit BTC. You can scan, copy, or share your Cashapp Bitcoin address with Lucky Charm Sweep or an external wallet. If you want to send Bitcoin, select the Banking tab and select BTC. You can scan the QR code to send the BTC.`}
          />

          <FAQItem
            index={22}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive Bitcoin using Coinbase?`}
            answer={`You can easily make all the mentioned transactions through Coinbase. See our detailed guide: `}
            link={`https://Lucky Charm Sweep.ag/coinbase/`}
          />

          <FAQItem
            index={23}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive Bitcoin using Kraken?`}
            answer={`You can easily make all the mentioned transactions through Kraken. See our detailed guide: `}
            link={`https://Lucky Charm Sweep.ag/kraken/`}
          />

          <FAQItem
            index={24}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive Bitcoin using Binance?`}
            answer={`You can easily make all the mentioned transactions through Binance. See our detailed guide:`}
            link={`https://Lucky Charm Sweep.ag/binance/`}
          />

          <FAQItem
            index={25}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive DOGE coin using Coinbase?`}
            answer={`Download Coinbase for your iPhone/Android device or use the desktop version. Set up an account, sign in and click “Trade”. Then Select DOGE in the “Buy” section, enter DOGE amount, choose payment method, and add your card details. Confirm the transaction after reviewing it.`}
            answer2={`To send DOGE, click the “Send” button, select DOGE, enter the DOGE amount, and paste the receiver’s wallet address. As for receiving DOGE, you will need to click “Receive,” select DOGE, and share your DOGE address with the sender.`}
          />

          <FAQItem
            index={26}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive DOGE coin using Kraken?`}
            answer={`Register on Kraken and top up the account balance. Then go to "Funding," click "Buy Crypto," select DOGE and, enter the amount, press “Continue,” verify your account (if you are purchasing for the first time), and complete the transaction.`}
            answer2={`To send DOGE, go to "Funding" again, select DOGE, and click "Withdraw", add the receiver's address, enter the DOGE amount, and submit the transaction. As for receiving, go to "Funding," click "Deposit," generate a new address (if you don't have it yet), and share it with the sender.`}
          />

          <FAQItem
            index={27}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive DOGE coin using Binance?`}
            answer={`Register on Binance, click "Buy Crypto," and use Credit/Debit card payment. Select DOGE and enter the amount. Proceed with the transaction by verifying your account and then completing the order by entering card details and confirming the purchase.`}
            answer2={`To send DOGE, hover over "Wallet" and then click "Overview". Press “Withdraw,” select DOGE, paste the wallet address, enter the amount, and confirm the transaction. To receive DOGE, go to "Deposit," click DOGE, select transaction network, copy the address, and share it.`}
          />

          <FAQItem
            index={28}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive Ether coin using Coinbase?`}
            answer={`Download Coinbase, go to “Trade,” select Ether (under the “Buy” section), enter the Ether amount, and choose the payment method. After entering the payment details, confirm the transaction.`}
            answer2={`To send Ether, click "Send," select Ether, and indicate the amount. Then paste receiver's address and submit the transaction. To receive Ether, go to "Receive," select Ether, and copy your Ether address.`}
          />

          <FAQItem
            index={29}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive Ether coin using Kraken?`}
            answer={`Register on Kraken, top up the account, and then go to "Funding." Click "Buy Crypto," choose Ether, enter the fiat amount, proceed by verifying your account, and complete the transaction.`}
            answer2={`To send Ether, go to "Funding", select Ether, click "Withdraw", paste the receiver's wallet address, then indicate the amount and complete the transaction. To receive Ether, go to "Funding" again and now click "Deposit," generate a new address if you need it, and share it.`}
          />

          <FAQItem
            index={30}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How do I buy, send, or receive Ether coin using Binance?`}
            answer={`Go to Binance, click "Buy Crypto" by card (if you want to use a Credit/Debit Card). Then, choose Ether, enter its amount, and proceed with verifying your account. Finally, complete the order by providing card details and confirming the transaction.`}
            answer2={`To send Ether, go to "Overview" by hovering over "Wallet". Then press "Withdraw," select Ether, paste the wallet address where you want to send your Ether, and indicate the amount. To receive Ether, go to the "Deposit" page, select Ether and transaction network, and copy the address to then share it.`}
          />

          <FAQItem
            index={31}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What is Dogecoin?`}
            answer={`Dogecoin is a cryptocurrency that is widely used all over the world. Dogecoin was invented in 2013. It is famous for its Shiba Inu dog logo. `}
          />

          <FAQItem
            index={32}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Can I use Dogecoin to make transactions on Lucky Charm Sweep?`}
            answer={`Yes, Lucky Charm Sweep allows for Dogecoin transactions. You can use Dogecoin both for withdrawals and deposits. `}
          />

          <FAQItem
            index={33}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Is it safe to use Dogecoin on Lucky Charm Sweep?`}
            answer={`Dogecoin is a safe cryptocurrency. The Dogecoin transactions are made with blockchain technology that ensures maximum security. Plus, Lucky Charm Sweep employs cutting-edge security software that provides additional safety for transactions.&nbsp;`}
          />

          <FAQItem
            index={34}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`What are the advantages of Dogecoin?`}
            answer={`In addition to fast payments and absolute anonymity, Dogecoin offers low transaction fees. It is a massive advantage over other cryptocurrencies that have been experiencing a rise in transaction costs lately. `}
          />

          <FAQItem
            index={35}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Is Dogecoin widely accepted?`}
            answer={`Yes, Dogecoin is a widely accepted cryptocurrency. It is used for many purposes, including shopping, entertainment, tipping, etc. Due to high demand, almost all platforms accepting crypto transactions support Dogecoin payments. `}
          />

          <FAQItem
            index={36}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Can I exchange Dogecoin for real money?`}
            answer={`Yes, you can exchange Dogecoin for real money with the help of a crypto exchange platform or a crypto wallet. `}
          />

          <FAQItem
            index={37}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How can I purchase Dogecoin?`}
            answer={`Dogecoin can be purchased on almost all crypto exchanges or wallets. For instance, you can try out Edge Wallet, a user-friendly app for making crypto transactions. `}
          />

          <FAQItem
            index={38}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How can I create an Edge Wallet?`}
            answer={`To set up an Edge Wallet, you should first download the app either for Android  or iOS  phones. After installing the application, proceed with registering an account. Once you set up the account, you will be able to generate personal crypto addresses for various cryptocurrencies, including Ethereum, Bitcoin, and Dogecoin. `}
          />

          <FAQItem
            index={39}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Is Edge Wallet safe?`}
            answer={`Yes, Edge is a safe crypto wallet. Edge Wallet enjoys high trust rates among customers and has positive feedback and reviews from crypto experts. `}
          />

          <FAQItem
            index={40}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Why should I use Edge Wallet?`}
            answer={`Besides safety, Edge Wallet provides access to numerous cryptocurrencies and is not limited to the major ones. In addition, Edge Wallet offers efficient customer support, available for customers 24/7. `}
          />

          <FAQItem
            index={41}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How can I deposit Dogecoin to Lucky Charm Sweep?`}
            answer={`To deposit Dogecoin to Lucky Charm Sweep, you will need a crypto wallet like Edge or a crypto exchange account.`}
            answer2={`First, go to the Lucky Charm Sweep deposits page and select your preferred platform. Then, proceed with checkout and enter the amount you want to transfer. Pick Dogecoin and copy the generated crypto address. `}
            answer3={`After this, you can go to your crypto wallet and use the mentioned crypto address to transfer Dogecoins to your Lucky Charm Sweep account. `}
          />

          <FAQItem
            index={42}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`How can I withdraw Dogecoin from Lucky Charm Sweep?`}
            answer={`To withdraw Dogecoin from Lucky Charm Sweep, you will need a crypto wallet or a crypto exchange account. `}
            answer2={`First, go to your crypto wallet account and copy your Dogecoin crypto address. Then come back to Lucky Charm Sweep and click withdrawals. Enter the amount of Dogecoin you want to withdraw, then paste your crypto wallet address into a relevant bar. Finally, review the transaction and confirm it. `}
          />

          <FAQItem
            index={43}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`Does Lucky Charm Sweep charge fees for Dogecoin transactions?`}
            answer={`No, Lucky Charm Sweep doesn’t charge any fees for Dogecoin transactions. That said, you need to remember that crypto transactions come with network fees. `}
          />

          <FAQItem
            index={44}
            openIndex={openIndex}
            toggleFAQItem={toggleFAQItem}
            question={`I won using the daily bonus. Can I withdraw these winnings immediately?`}
            answer={`Platform credits are specifically designed to amplify your gaming experience, allowing you to explore the available games. Therefore they can only be used as in-game tokens, not the actual currency. To leverage these credits, you must initially deposit actual funds through a wide range of payment methods that are offered at Lucky Charm Sweep. So, complete the withdrawal, please make a deposit first, earn exceptional bonuses, play the best casino games, and then proceed to cash out. `}
          />

        </div>
      </div>
    </section>
  );
};




export default FaqContent;
