import { adminApi } from "./changepass";

const DataFetching = async (
    setLoding,
    url,
    method,
    token,
    dispatch,
    navigate,
    setFilteredData,
    setTotalPages,
    body,
    setResetSearch,
    resetSearch,
) => {
    setLoding(true);
    try {
        const result = await adminApi(url, method, body, token, dispatch, navigate);
        if (result.status.code === 1) {
            if (result.status.code === 1 && result.data !== null) {
                setFilteredData(result?.data?.history);
                setTotalPages(result?.data?.total_record_fatch);
            } else {
                setFilteredData([]);
            }
        } else {
            setFilteredData([]);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        setLoding(false);
        if (resetSearch) {
            setResetSearch(false);
        }
    }
};

export default DataFetching;
