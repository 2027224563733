import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GamesDescriptionBg from '../../../assets/image/gamesDescriptionBg.png'
import MainContent from '../../../components/gamesDescription/MainContent'
import Hero from '../../../components/Hero'
import axiosInstance from '../../../utils/AxiosInstance'
import Loading from '../../../components/Common/Loading'

const DiamondShotGame = () => {
    const { gameID } = useParams();

    const [gameDetails, setGameDetails] = useState(null);

    useEffect(() => {
        const fetchGameDetails = async () => {
            try {
                const response = await axiosInstance.post('/get_game_list.php', { gameID });
                const gameData = response.data.data[0];
                setGameDetails(gameData);
            } catch (error) {
                console.error('Failed to fetch game details:', error);
            }
        };

        fetchGameDetails();
    }, [gameID]);

    if (!gameDetails) {
        return <div>
            <Loading />
        </div>;
    }

    return (
        <>
            <Hero title={gameDetails?.game_name} bgImg={GamesDescriptionBg} backgroundColor='#290A47' />
            <MainContent gameDetails={gameDetails} />
        </>
    )
}

export default DiamondShotGame